.c-video-modal{
    --close-size: 50px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.7);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: $z-i-modal;
    overflow:hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__video {
        position: relative;
        padding-top: 56%;
        z-index: 1;
        video {
            @extend %absoluteCentered;
            width: 100%;
            height: 100%;
            &:focus {
                outline: none;
            }
        }
    }
    &__close {
        position: absolute;
        top: $componentVerticalPadding;
        right: $componentVerticalPadding;
        cursor: pointer;
        z-index: 3;
    }
}
