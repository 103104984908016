.l-cms-page{
    //min-height: 100vh;
    position: relative;
    .page{
        &__main-content{
            //padding: rem(50) 0;
        }
    }
    &.faq{
        .page{
            &__main-content{
                @include mainContainer();
                display:grid;
                grid-template-columns: 1fr 2fr;
                gap: rem(80);
                .c-anchor-title{
                    .anchor__title{
                        width: 100%;
                    }
                }
                .c-question-answer{
                    .q-a__wrapper{
                        width: 100%;
                    }
                }
            }
        }
    }
}

@include responsiveTo('lg'){
    .l-cms-page{
        &.faq{
            .page{
                &__main-content{
                    grid-template-columns: 1fr;
                }
            }
        }
    }
}
