.c-twin-cards{
  position: relative;
  margin: rem(30) 0;
  @extend %with-vertical-label;
  @include mainContainer();
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  &.c-twin-cards--one-only {
    grid-template-columns: 1fr;
  }
  &:before {
    color: getColor('lightgray') ;
  }
}

@include responsiveTo('md'){
  .c-twin-cards{
    grid-template-columns: 1fr;
    &:before{
      right: 100%;
    }
  }
}