.c-chat-wee-block{
    $chatW: 700px;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: $z-i-modal;
    transition: all ease 1s;
    &--hidden {
        right: -$chatW;
        opacity: 0;
    }

    &__container {
        width: $chatW;
        height: 500px;
        background: getColor('text');
        color: getColor('text-dark');

        #chatwee-widget{
            width: $chatW;
            height: 500px;
            background: getColor('text');
            .c-loader {
                @extend %absoluteCentered;
            }
        }
    }


    .chat-wee{
        &__close{
            background:getColor('text');
            color: getColor('text-dark');
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            min-height: 2rem;
            min-width: 7rem;
            .wip-icon{
                margin-right: rem(7);
            }
        }
        &__invite{
            display:flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            padding: rem(30);
            text-align: center;
            &__title{
                @extend %title;
                color: getColor('secondary');
            }
            &__description{
                @extend %description;
            }
            &__note{
                font-size: rem(12);
                color: getColor('lightgray');
                margin-bottom: rem(40);
            }
        }
    }
}



@include responsiveTo('md'){
    $chatW: 320px;
    .c-chat-wee-block {
        &__container {
            width: $chatW;
        }
        &--hidden {
            right: -$chatW;
        }
    }

    // The Devil:
    // overriding third party chat forcing on mobile to be always open
    // a custom ehea component will destroy chat instance to close it


    // chat in smartphone is not in panel
    // third party script inject it outside , in body
    // in case of user "enabled" but not "participant "the panel show acceptance form
    // so here we hide the ehea chat panel in case of mobile & chat participant user
    .c-chat-wee-block--participant {
        display: none;
    }

    // hide chat default fixed floating button
    .chch-fixedPane {
        display: none !important;
        opacity: 0;
        pointer-events: none;
    }
    // show chat default fixed floating button
    .chch-mobileChatweeWindow {
        display: block !important;
    }
    // hide mobile chat closer
    .chch-mobileToolBarCloseLabel {
        display: none !important;
    }
}
