// @import '~normalize.css';

/* $ComponentPadding: Distance of contents from page: as the main wrapper margin set by _u_grid */
$ComponentPadding:  calc( var(--main-container-pad-x) * 1px);
$DoubleComponentPadding:  calc( var(--main-container-pad-x) * 2px);
$ComponentverticalPadding: $siteComponentsMargin;
$maxTextWidth: $siteMaxBodyText;

.wip-fullheader {
  --bg-overlay: rgba(0,0,0,.6);
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: $ComponentverticalPadding;
  padding-bottom: $ComponentverticalPadding;
  margin-bottom: $ComponentverticalPadding;

  &.no-margin {
    margin-bottom: 0;
  }

  &__item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    min-height: 30rem;
    background-size: cover;
    background-position: 50% 50%;

    box-shadow: $sectionsShadow;
    .wip-fullheader__contentwrp {
      max-width: rem(900);
      text-align: center;
      .wip-fullheader__contentwrp__cta {
        justify-content: center;
      }
    }
    &.dark {
      --bg-overlay: rgba(140,140,140,.4);
      .wip-fullheader__contentwrp {
        &__pretitle,
        &__title,
        &__subtitle,
        &__text {
          color: getColor('text-dark');
        }
      }
    }

  }

  &__imagewrp{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    &__image{
      display: block;
      width: 100%;
      height:100%;
      img{
        height:100%;
        object-fit: cover;
      }
    }
    &:after{
      content: '';
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height:100%;
      background: var(--bg-overlay);
    }
  }

  // Content into flex container
  &__contentwrp {
    position: relative;
    color: getColor('text');
    &__pretitle {
      @extend %pretitle;
    }
    &__title {
      @extend %big-title;
    }
    &__subtitle {
      @extend %subtitle;
    }
      &__extra-subtitle{
          @extend %title;
          color: getColor('secondary');
          margin-top: rem(30);
      }
    &__text {
      padding-top: 2em;
      p:last-child {
        margin-bottom: 0;
      }
    }
    //CTA
    &__cta {
      display: flex;
      margin-top: 1em;
      button + button,
      button + input[type="button"],
      button + a,
      input[type="button"] + button,
      input[type="button"] + input[type="button"],
      input[type="button"] + a,
      a + button,
      a + input[type="button"],
      a + a {
        margin-left: 0.5em;
      }
    }

  }

}

//end component

/* ------------- smartphone and tablet ------------- */

@include responsiveTo(lg) {

  .wip-fullheader {

    &__item {
      // Image wrapper into flex container
      .wip-fullheader__imagewrp {
        min-width: 0px; /* <--- to avoid slick problems! */
        flex: 1 1 100%;
        &__image {
          margin: 0;
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
      .wip-fullheader__contentwrp {
        font-size: 0.9em;
        padding-top: $ComponentPadding;
        @include mainContainer();
        &__title{
          line-height: 1.2;
        }
      }
    }
  }
}

/* ------------- desktop ------------- */

@include responsiveFrom(lg) {
  .wip-fullheader {
    &__item {
      width: 100%;
      &--left {
        justify-content: flex-start;
        .wip-fullheader__contentwrp {
          text-align: left;
          width: 50%;
          padding-left: $DoubleComponentPadding;
          .wip-fullheader__contentwrp__cta {
            justify-content: flex-start;
          }
        }
      }
      &--right {
        justify-content: flex-end;
        .wip-fullheader__contentwrp {
          text-align: left;
          width: 50%;
          padding-right: $ComponentPadding;
          .wip-fullheader__contentwrp__cta {
            justify-content: flex-start;
          }
        }
      }

    }
  }
}




/* --------------------------------------------------- */
/* ------- scroll animation -------------------------- */
/* --------------------------------------------------- */


/* ------- scroll animation: transitions & delays ---- */

.wip-fullheader__imagewrp__image {
  transition: all 0.5s ease;
}

.wip-fullheader__contentwrp {
  > div {
    @for $i from 1 to 10 {
      &:nth-child(#{$i}) {
        transition: all 1s ease ($i) * 0.3s;
      }
    }
  }
}

/* ------- scroll animation: out properties ---------- */

.out-view {
  .wip-fullheader__imagewrp__image {
    transform: scale(0.9);
    opacity: 0;
  }
  .wip-fullheader__contentwrp {
    > * {
      opacity: 0;
    }
  }
}
