.c-page-headings {
  @include mainContainer();
  text-align: center;
  &__title {
    @extend %big-title;
  }
  &__text {
    @extend %description;
  }
}
