.l-home-tools {

  .c-card-abstract {
    background: getColor('smokegray');
    padding-left: 6rem;
    &.l-home-tools__item {
      background-size: 60px 60px;
      background-repeat: no-repeat;
      background-position: 3rem 5rem;
    }
    &.l-home-tools__item--chat {
      background-image: url("../images/icon-chat.svg");
    }
    &.l-home-tools__item--support {
      background-image: url("../images/icon-question.svg");
    }

    .card-abs__wrapper {
      width: 100%;
      justify-content: center;
      min-height: 0;
    }
    .card-abs__head__pretitle {
      color: getColor('darkblue');
      font-weight: $font-bold;
    }
    .card-abs__head__title {
      color: getColor('text')
    }
  }

}

@include responsiveTo('md') {
  .l-home-tools {
    .c-card-abstract {
      padding-top: 6rem;
      padding-left: 0rem;
      &.l-home-tools__item {
        background-size: 60px 60px;
        background-position: 50% 2.5rem;
      }
    }
  }
}
