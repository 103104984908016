.l-home{
    section{
        position: relative;
        margin: rem(30) 0;
        @extend %with-vertical-label;
        &:before {
            color: getColor('lightgray') ;
        }
        .card-abs__head__title {
            color: getColor('secondary');
        }
    }
    .home{
        &__live-conference,
        &__twin-cards,
        &__concept,
        &__program,
        &__ehea-partners{
            margin: rem(90) auto;
            @include mainContainer();
        }

        &__live-conference {
            margin-top:0;
            .c-card-abstract {
                .card-abs__head__title {
                    font-size:3em;
                }
            }
        }
        &__twin-cards,
        &__program{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 30px;
        }
        &__program{
            margin-top: rem(90);
            .c-event-program-table{
                box-shadow: $sectionsShadow;
                background: getColor('darkprimary');
            }
        }
    }
}

@include responsiveTo('md'){
    .l-home{
        section{
            &:before{
                right: 100%;
            }
        }
        .home{
            &__twin-cards,
            &__program{
                grid-template-columns: 1fr;
            }
            &__live-conference {
                .c-card-abstract {
                    .card-abs__head__title {
                        font-size:1.8rem;
                    }
                }
            }
        }
    }
}

