@import "../components/_c_field";
@import "../components/_c_input";
@import "../components/_c_inputfile";
@import "../components/_c_inputdate";
@import "../components/_c_options-group";
@import "../components/_c_switch";
@import "../components/_c_textarea";
@import "../components/_c_select";
@import "../layouts/_l_inputgroup";




/* -----------------  required utilities ----------------- */
.required-info {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  $rowWidth: 3rem;
  $rowDistance: 1rem;
  .required-sign {
    padding-right: $rowDistance;
    opacity: 1;
  }
  &__text {
    position: relative;
    padding-left: $rowDistance + $rowWidth;
    &::before {
      position: absolute;
      top:50%;
      left: 0;
      border: 1px solid getColor('lightgray');
      content: '';
      width: $rowWidth;
    }
  }
}
.required-sign {
  font-size: rem(16);
  color: getColor('secondary');
  opacity: 0;
}

