.c-press-doc{
    --btns-size: 78;
    --template-cols: auto calc((var(--btns-size) + 2) * 2 * 1px);
    --gap: #{rem(100)};
    --justify-triggers: flex-end;
    margin-bottom: rem(45);
    .trigger{
        cursor: pointer;
        color: currentColor;
        background: getColor('darkgray');
        width: calc(var(--btns-size) * 1px);
        height: calc(var(--btns-size) * 1px);
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        &__label{
            font-size: rem(11);
        }
    }
    .press{
        &__wrapper{
            @include mainContainer();
            display: grid;
            grid-template-columns: var(--template-cols);
            gap: var(--gap);
            align-items: flex-end;
            border-bottom: 2px solid getColor('darkgray');
        }
        &__content{
            &__date,
            &__pretitle,
            &__title,
            &__body{
                margin-bottom: rem(15);
            }
            &__title{
                font-size: rem(18);
                font-weight: $font-semi-bold;
            }
        }
        &__triggers{
            display: flex;
            justify-content: var(--justify-triggers);
            .trigger{
                &:last-child{
                    margin-left: rem(5);
                }
            }
        }
    }
}

@include responsiveTo('md'){
    .c-press-doc{
        --template-cols: 1fr;
        --gap: #{rem(20)};
        --justify-triggers: flex-end;
    }
}
