.l-header-navs{
    position: relative;
    opacity: 1;
    transition: opacity .8s ease-out;
    z-index: $z-i-header;
    margin-top: 0;
    &.hidden{
        opacity: 0;
        margin-top: -3rem;
        pointer-events: none;
    }
    &.on-conference{
        .c-nav-service{
            .c-nav-service__item{
                display:none;
                &--icons{
                    display:block;
                }
            }
        }
        .c-navbar__menu{
            display:none;
        }
    }
}
