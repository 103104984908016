.c-input{
    @extend %c-field;
    input{
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;
        padding: 0;
        color: getColor('text');
        &:-internal-autofill-selected {
            background-color: transparent;
        }
    }
}
