.c-force-orientation {
  cursor: pointer;
  border: 1px solid getColor('darkprimary');
  border-radius: 0.5em;
  box-shadow:  0px 0px 30px 0px rgba(0, 0, 0, 0.5);
  background: rgba(34, 46, 61, 0.3);
  text-align: center;
  padding: 1rem;
  .wip-icon svg {
    width: 4rem;
    height: 4rem;
    margin: 2rem 0 1rem;
    transform-origin: 50% 50%;
    animation-name: fadeRotate;
    animation-duration: 1s;
    animation-fill-mode: backwards;
  }
  &__content {
    &__title {
      @extend %pretitle;
      animation: fadeUp 1s backwards;
    }
    &__description {
      @extend %description;
      animation: fadeUp 1s backwards 0.5s;
    }
  }
}

@keyframes fadeRotate {
  0%   {
    opacity: 0;
    transform: rotate(-90deg);
  }
  100% {
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes fadeUp {
  0%   {
    opacity: 0;
    transform: translateY(1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
