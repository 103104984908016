$maxTextWidth: $siteMaxBodyText;

.c-wip-abstract-text {
    .abstract {
        &__wrapper {
            @include mainContainer();
            max-width: $maxTextWidth;
            color: getColor('secondary');
            position: relative;
        }
        &__title {
            position: absolute;
            color: getColor('lightgray');
            font-size: rem(35);
            font-weight: $font-semi-bold;
            text-transform: uppercase;
            //top: rem(10);
            //writing-mode: vertical-rl;
            //transform: rotate(180deg);
            //right:calc(100% + #{rem(25)});
            transform: rotate(-90deg) translate(-100%,-200%);
            transform-origin: top left;
            top: 0;
            left: 0;
        }
        &__content {
            line-height:1.5;
            font-family: $font-family-secondary;
            font-size: rem(22);
            //text-transform: lowercase;
            //&::first-letter {
            //    text-transform: uppercase;
            //}
        }
    }
}

@include responsiveTo('lg'){
    .c-wip-abstract-text{
        .abstract{
            &__title{
                writing-mode: horizontal-tb;
                transform: none;
                position: static;
            }
        }
    }
}
