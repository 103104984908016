$border-radius: 3px;
$componentVerticalPadding: 2rem;
$font-family-base: Arial, sans, arial;
$sectionsShadow: 0 1.5rem 2.5rem -1rem rgba(0, 0, 0, 0.5);

/* -------------------------------------------------- */
/* TO REMOVE FROM COMPONENTS IN ORDER TO WORK CLEANER:
   SITE MARGINS AND INNER TOWER */
/* -------------------------------------------------- */

// site width: margins for UI Elements (menu, logo, ...)
$siteWidth: 96vw;
$sitePadding: 2vw;
// inner width: margins for content "tower" (paragraph, cms component contents, ...)
$siteInnerWidth: 90vw;
$siteInnerPadding: 5vw;

// site width: margins for UI Elements (menu, logo, ...)
$siteWidthSmall: 88vw;
$sitePaddingSmall: 6vw;
// inner width: margins for content "tower" (paragraph, cms component contents, ...)
$siteInnerWidthSmall: $siteWidthSmall;
$siteInnerPaddingSmall: $sitePaddingSmall;

/* -------------------------------------------------- */
/* ----------- COMPONENTS GENERAL RULES ------------- */
/* -------------------------------------------------- */

$siteComponentsMargin: 30px;  /* vertical margin (padding when needed) */
$siteMaxBodyText: 800px;
