.c-sidebar-nav{
    position: fixed;
    top:40%;
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: flex-end;
    z-index: $z-i-sidebar;
    pointer-events: none;
    .sidebar-nav{
        &__wrapper{
            display: flex;
            flex-flow: column;
            align-items: flex-end;
            overflow: hidden;
        }
        &__trigger{
            height: rem(50);
            display: grid;
            grid-template-columns: 1fr rem(50);
            margin-bottom: rem(10);
            .trigger{
                &__label,
                &__btn{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &__label{
                    background: getColor('darkred');
                    padding: 5px 10px;
                    transform: translateX(100%);
                    transition: transform .3s cubic-bezier(0.51, 0.1, 0.43, 1.01);
                }
                &__btn{
                    z-index: $z-i-main;
                    background:getColor('red');
                    cursor: pointer;
                    pointer-events: all;
                }
            }
            &:hover{
                .trigger{
                    &__label{
                        transform: translateX(0);
                    }
                }
            }
        }
    }
}
