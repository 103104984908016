.c-notification {
  position: relative;
  background: getColor('darkprimary');
  padding: 1rem 2rem;
  border-top: 2px;
  border-right: 2px;
  border-bottom: 2px;
  border-left: 8px;
  border-style: solid;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.8);
  border-radius: 3px;
  opacity: 0.8;
  margin:3rem 0;
  &--error {
    border-color: getColor('error');
  }
  &--warning {
    border-color: getColor('warning');
  }
  &--success {
    border-color: getColor('success');
  }
  &--bright {
    background: white;
    color: getColor('text-dark');
    box-shadow: 0 0 2rem 0px rgba(0, 0, 0, 0.2);
  }
}