.c-inputfile{
    @extend %c-field;
    &.filled {
        &:after {
            content: '√';
        }
        .inputfile__label {
            top: 50%;
            transform: translate(0,-50%);
            font-size: inherit;
        }
    }
    input{
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;
        color: getColor('text');
        padding: 1rem 0;
        &:-internal-autofill-selected {
            background-color: transparent;
        }
        opacity: 0;
    }
}