.c-event-note{
    --event-template: #{rem(155)} 1fr 2fr 1fr;
    margin: rem(60) 0;
    .note{
        &__wrapper{
            @include mainContainer();
            display:grid;
            grid-template-columns: var(--event-template);
            align-items: center;
            justify-items: center;
            border: 2px solid getColor('primary');
            &>div{
                padding: rem(30);
            }
        }

        &__label{
            color: getColor('text');
            background: getColor('primary');
            width: 100%;
            height: 100%;
            text-align: center;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
        }
        &__schedule{
            &__date{
                display:flex;
                align-items:flex-end;
                color: getColor('secondary');
                line-height:1;
                font-weight: $font-semi-bold;
                &:before{
                    content: attr(data-day);
                    font-size: rem(34);
                }
                &:after{
                    content:'/'attr(data-month);
                    font-size: rem(24);
                }
            }
            &__day{
                display: block;
                font-size: rem(20);
            }
        }
        &__event{
            display:flex;
            align-items: flex-start;
            &__time{
                color: getColor('tertiary');
                flex: 0 0 25%;
            }
            &__description{
                .title{
                    font-size: rem(20);
                }
                .content{
                    font-size: rem(14);
                }
            }
        }
    }
}

@include responsiveTo('lg'){
    .c-event-note{
        --event-template: 1fr 1fr;
        .note{
            &__event{
                flex-flow: row wrap;
                border-right: 1px solid var(--color-primary);
                &__time{
                    flex:  0 0 100%;
                }
            }
        }
    }
}

@include responsiveTo('md'){
    .c-event-note{
        --event-template: 1fr;
        .note{
            &__event{
                border: none;
            }
        }
    }
}
