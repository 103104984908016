.c-video-wide{
    margin-top: rem(90);
    margin-bottom: rem(90);
    video{
        width: 100%;
    }
    .video{
        &__wrapper{
            width: 100%;
        }
    }
    &.boxed{
        .video{
            &__wrapper{
                @include mainContainer();
            }
        }
    }
}
