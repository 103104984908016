.c-exhibition-video{
    width: 100%;
    height: 100%;
    background: black;
    position: relative;
    --opacity-controls: 1;
    --pointer-evs:none;
    video{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .exhibition-video{
        &__controls{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;
            z-index: $z-i-main;
            [class^='control-']{
                opacity: var(--opacity-controls);
                cursor: pointer;
                pointer-events: var(--pointer-evs);
                transition: opacity .4s ease-in-out;
            }
        }
    }
    &:hover{
        --pointer-evs: all;
    }

    &.playing{
        --opacity-controls: 0;
        &:hover{
            --opacity-controls: 1;
        }
    }
}
