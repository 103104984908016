.c-event-row {
    --hover-bg: transparent;
    --vertical-divisor-color: rgba(255,255,255,.4);
    --body-margin-top: 0;
    --pad-wrapper: 0;
    --pos-vertical-line: calc(15% + #{rem(10)} + #{rem(5)});
    --grid-cols-row: 15% 15% 1fr;
    .ev-row {
        &__flag{
            display:block;//TODO: develop this flag for second phase
            color: white;
            position: absolute;
            top: 0;
            left: rem(-15);
            padding: rem(7) rem(20) rem(7) rem(13);
            &:before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: red;
                clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%);
            }
            &:after{
                content: attr(data-flag);
                color: currentColor;
                position: relative;
            }
        }
        &__wrapper{
            background: var(--hover-bg);
            position: relative;
            padding: var(--pad-wrapper);
            transition: background .3s ease-in-out;
            &:after{
                position: absolute;
                top: 0;
                //calc size of first column plus half of grid gap (10 from 20px)
                // plus half of head/body padding (5 from 10px)
                left: var(--pos-vertical-line);
                content:'';
                height: 100%;
                width: 0;
                border-right: 1px solid var(--vertical-divisor-color);
            }
        }
        &__label,
        &__head,
        &__body{
            display:grid;
            grid-template-columns: var(--grid-cols-row);
            gap: rem(20);
            padding: rem(5) rem(20);
        }
        &__label{
            position: relative;
            text-transform: uppercase;
            padding: rem(20) rem(10);
            .ev-row__time{
                font-size: rem(10);
                text-align: right;
            }
        }
        &__label-icon{
            position: absolute;
            left: calc(15% + #{rem(5)} );
            top: 50%;
            transform: translateY(-50%);
        }
        &__time {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
            font-size: rem(16);
            font-weight: $font-semi-bold;
            span{
                flex: 0 0 100%;
                font-size: rem(14);
            }
            &:nth-child(2){
                justify-content: flex-end;
                color: getColor('tertiary');
                span{
                    text-align: right;
                }
            }
        }
        &__title{
            font-weight: $font-semi-bold;
            font-size: rem(16);
            .wip-icon{
                margin-right: rem(10);
            }
        }
        &__content{
            grid-column: 3/-1;
            &__description{
                font-size: rem(13);
                line-height: 1.3;
                margin-bottom: rem(10);
                & ~ .ev-row__content__authors{
                    margin-top: rem(20);
                }
            }
            &__authors{
                //display: none;
                font-size: rem(14);
                .author{
                    margin-bottom: rem(5);
                    &__country{
                        text-transform: uppercase;
                        font-weight: $font-semi-bold;
                    }
                    &__role{
                        font-style: italic;
                        &:before{
                            content: ',';
                            margin-right: rem(5);
                        }
                    }
                }
            }
        }
        &__body{
            height:auto;
            max-height: 0;
            overflow: hidden;
            padding: 0 rem(20);
            margin-top: var(--body-margin-top);
        }
    }

    //highlighted
    &.highlight{
        --pad-wrapper: #{rem(10)} 0;
        --body-margin-top: #{rem(12)};
        .ev-row{
            &__head{
                border: 1px solid currentColor;
                border-radius: 1em;
                padding: rem(10) rem(20);
            }
        }
    }
    //Break, lunch, end states of row
    &.break-time{
        .ev-row{
            &__time{
                &:nth-child(2){
                    color: currentColor;
                }
            }
        }
    }
    //open body
    &.open-body{
        .ev-row{
            &__body{
                max-height: 9999px;
                transition: all .5s ease-in-out;
            }
        }
    }
    //into sidebar
    &.in-sidebar{
        --vertical-divisor-color: #{getColor('lightgray')};
        --pad-wrapper: #{rem(10)} #{rem(30)} #{rem(10)} #{rem(50)};
        --pos-vertical-line: calc(15% + #{rem(10)} + #{rem(5)} + #{rem(30)});
        color: getColor('dark');
        .ev-row{
            &__label{
                padding: var(--pad-wrapper);
            }
            &__label-icon{
                left: calc(15% + #{rem(5)} + #{rem(30)});
            }
            &__content__authors{
                display:block;
            }
        }
        &.highlight{
            .ev-row{
                &__head{
                    border-color: transparent;
                    background: white;
                }
            }
        }
        &.on-air{
            --hover-bg: #{getColor('smoke')};
            //.ev-row{
            //    &__wrapper{
            //        background: var(--hover-bg);
            //    }
            //}
        }
    }
}

@include responsiveTo('md'){
    .c-event-row{
        --grid-cols-row: 1fr 1fr;
        margin-bottom: rem(20);
        .ev-row{
            &__wrapper{
                border-top: 1px solid var(--vertical-divisor-color);
                &:after{
                    display: none;
                }
            }
            &__title{
                grid-column: 1/-1;
            }
            &__content{
                grid-column: 1/-1;
            }
        }
        &.in-sidebar{
            --pad-wrapper: #{rem(10)} #{rem(20)} #{rem(10)} #{rem(40)};
        }
    }
}
