$theme-colors: (
        'primary':#467D9B,
        'secondary': #FAAB45,
        'tertiary':#379F7D,
        'light':#ffffff,
        'lightgray': #959595,
        'smokegray' : #727C8B,
        'darkgray': #3f4650,
        'darkblue': #222E3D,
        'darkprimary': #06415F,
        'dark': #222222,
        'text': #ffffff,
        'text-dark' : #000000,
        'text-gray' : #6E92A5,
        'red': #EB282D,
        'darkred': #B30E12,
        'smoke': #D2D2D2,
        'lightsmoke': #E4E4E4,
        'success': #00a905,
        'warning': #fa6700,
        'error': #B30E12
);

// creates classes for color and background with each variant color
@each $color, $val in $theme-colors {
  .bg-#{$color} {
    background-color: $val;
  }
  .color-#{$color} {
    color: $val;
  }
  :root {
    --color-#{$color}: #{$val};
  }
}

@function getColor($key:'primary') {
  @return map-get($theme-colors, $key)
}
