$maxTextWidth: $siteMaxBodyText;
$citeBarSize: 0.25rem;
$ComponentverticalPadding: $siteComponentsMargin;

.wip-cite {
  position: relative;
  padding-top: $ComponentverticalPadding;
  padding-bottom: $ComponentverticalPadding;
  margin-top:$ComponentverticalPadding;
  margin-bottom:$ComponentverticalPadding;
  @include mainContainer();
  max-width: $maxTextWidth;
  padding-left: 2rem;
  &::before {
    position: absolute;
    top:0;
    left:0;
    width: $citeBarSize;
    background: getColor('primary');
    height: 100%;
    content:'';
    opacity: 0.2;
  }

  &__content {
    font-size: 1.8em;
    font-style: italic;
    p {
      margin-top: 0;
    }
  }
  &__source {
    font-size: 0.8em;
    text-transform: uppercase;
  }
  &__author {
    font-style: italic;
    font-weight: 700;
  }

}





/* --------------------------------------------------- */
/* ------- scroll animation -------------------------- */
/* --------------------------------------------------- */


/* ------- scroll animation: transitions & delays ---- */

.wip-cite {
  > [class*="wip-cite__"] {
    @for $i from 1 to 5 {
      &:nth-child(#{$i}) {
        transition: all 1s ease ($i) * 0.2s;
      }
    }
  }
}

/* ------- scroll animation: out properties ---------- */

.out-view {
  .wip-cite__title {
    opacity: 0;
    transform: translateX(0.2em);
  }
  .wip-cite__text {
    transform: translateX(-0.2em);
    opacity: 0;
  }
}