.c-card-abstract{
    --card-bg-color: #{getColor('primary')};
    --card-bg-transform: scale(1);
    --min-h-wrapper: #{rem(400)};
    background: var(--card-bg-color);
    position: relative;
    box-shadow: $sectionsShadow;
    &:hover{
        --card-bg-transform: scale(1.2);
    }
    .card-abs{
        &__bg-img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: var(--card-bg-transform);
                transition: transform .7s ease-in-out;
            }
            &:after{
                content:'';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,.5);
                pointer-events: none;
            }
        }
        &__wrapper{
            position: relative;
            width: 70%;
            padding: rem(60);
            min-height: var(--min-h-wrapper);
            display:flex;
            flex-flow: column;
            justify-content: flex-end;
        }
        &__head{
            padding: 0 0 rem(10) 0;
            &__pretitle{
                @extend %pretitle;
            }
            &__title{
                @extend %title;
            }
        }
        &__content{
            padding:  rem(10) 0 rem(10) 0;
        }
        &__cta{
            padding: rem(10) 0 0 0;
            display: flex;
        }
    }

    &.conference{
        --min-h-wrapper: #{rem(500)};
    }
}

@include responsiveTo('md'){
    .c-card-abstract{
        .card-abs{
            &__wrapper {
                width: 100%;
                padding: rem(30);
            }
        }
        &.conference{
            --min-h-wrapper: #{rem(300)};
        }
    }
}



/* --------------------------------------------------- */
/* ------- scroll animation -------------------------- */
/* --------------------------------------------------- */


/* ------- scroll animation: transitions & delays ---- */


.card-abs__wrapper > div {
    opacity: 0;
    transform: translateY(-1rem);
    @for $i from 1 to 5 {
        &:nth-child(#{$i}) {
            transition: all 1s ease ($i) * 0.3s;
        }
    }
}

.in-view {
    .card-abs__wrapper > div {
        opacity: 1;
        transform: translateY(0rem);
    }
}
/* ------- scroll animation: out properties ---------- */

.out-view {
    .c-card-abstract .card-abs .card-abs__wrapper > div  {
        opacity: 0;
    }
}
