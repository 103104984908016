.c-column-single{
    .column{
        &__imagewrp {
            min-width: 0; /* <--- to avoid slick problems! */
            flex: 1 1 50%;
            padding: $ComponentverticalPadding/2 0;
            position:relative;
            &--video {
                cursor: pointer;
                .wip-icon {
                    @extend %absoluteCentered;
                    color: white;
                    z-index: 5;
                    border: 2px solid white;
                    border-radius: 50%;
                    width: 3rem;
                    height: 3rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-left: 0.2rem;
                }
            }
            &__image {
                margin: 0;
                width: 100%;
                text-align: center;
                img {
                    max-width: 100%;
                }
            }
        }

        &__contentwrp {
            &__label{
                display:inline-block;
                background: getColor('lightgray');
                padding: rem(3) rem(5);
                margin-bottom: rem(15);
            }
            &__pre-title {
                @extend %pretitle;
            }
            &__title {
                @extend %title;
                margin-bottom: rem(20);
            }
            //&__subtitle {
            //  @extend %subtitle;
            //}
            &__text {
                --max-lines: 4;
                --f-size: 14px;
                --px-line-h-text: 20px;
                font-size: var(--f-size);
                line-height: var(--px-line-h-text);
                position: relative;
                max-height: calc(var(--px-line-h-text) * var(--max-lines));
                overflow:hidden;
                padding-right: 1rem;
                transition: height .5s ease-in-out;
                p{
                    margin: 0 0 rem(7);
                }
                p:last-child {
                    margin-bottom: 0;
                }
            }
            //CTA
            &__cta {
                display: flex;
                margin-top: 1em;
                button + button,
                button + input[type="button"],
                button + a,
                input[type="button"] + button,
                input[type="button"] + input[type="button"],
                input[type="button"] + a,
                a + button,
                a + input[type="button"],
                a + a {
                    margin-left: 0.5em;
                }
            }
            //show more dots
            .dots{
                cursor: pointer;
                font-size: rem(40);
                line-height: 0.2;
                color: getColor('tertiary');
            }
        }
    }
    &.show-more{
        .column{
            &__contentwrp{
                &__text{
                    max-height: 9999px;
                }
            }
        }
    }
}
