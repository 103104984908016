.l-contact {
  &__content {
    @include mainContainer();
    max-width: rem(800);
    margin-top: 2*$componentVerticalPadding;

    &__userfields {
    }
    &__request {
    }
    &__cta {
      display: flex;
      justify-content: center;
      button {
        min-width: rem(250);
      }
    }
  }
}

@include responsiveFrom(lg) {
  .l-contact {
    &__content {
      &__header {
        display: flex;
        justify-content: space-between;
        align-content:center;

      }
      &__userfields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;
      }
    }
  }
}

@include responsiveTo(lg) {
  .l-contact {
    &__content {
      &__header {
        .required-info{
          margin-top:1rem;
        }
      }
    }
  }
}