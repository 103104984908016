.c-question-answer{
    margin-top: rem(50);
    margin-bottom: rem(50);
    .q-a{
        &__wrapper{
            @include mainContainer();
            border-bottom: 1px solid getColor('darkgray');
        }
        &__question{
            color: getColor('tertiary');
            font-size: rem(20);
            font-family: $font-family-secondary;
            display:flex;
            align-items:center;
            justify-content: space-between;
            padding: rem(20) 0 rem(10);
            &:after{
                content: '\002B';
                display:block;
                color: getColor('secondary');
                font-size: rem(25);
            }
        }
        &__answer{
            color: getColor('text');
            letter-spacing: 1px;
            height:auto;
            max-height: 0;
            overflow: hidden;
            opacity:0;
        }
    }
    &.opened{
        .q-a{
            &__question{
                &:after{
                    content:'\2212';
                }
            }
            &__answer{
                opacity:1;
                max-height:9999px;
                transition: all .7s ease-in-out;
            }
        }
    }
}

