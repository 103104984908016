// TODO: importing from css doesn't permit webpack to manage css chunks
@import './_variables';

/* ---------------------------------------------------------------- */
/* 1. ABSTRACTS                                                     */
/* ---------------------------------------------------------------- */

/* In Abstracts you can write your variables or mixins              */
/* You override Bootstrap variables even if it's before it          */

 @import "./abstracts/_a_custom_variables";
 @import "./abstracts/_a_colors";

/* ---------------------------------------------------------------- */
/* 2 Utils:                                                       */
/* ---------------------------------------------------------------- */

@import "./utils/u_functions";
@import "./utils/u_normalize";
@import "./utils/u_grid";
@import "./utils/u-z-indexes";
@import "./utils/u-shortcuts";

/* ---------------------------------------------------------------- */
/* 3. VENDORS                                                       */
/* ---------------------------------------------------------------- */

// Glidejs Galleries: https://glidejs.com/docs/
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

/* ---------------------------------------------------------------- */
/* 3.1 VENDORS OVERRIDES                                            */
/* ---------------------------------------------------------------- */
@import "vendors/v-perfect-scroll";
@import "vendors/v-glide";
@import "vendors/v-zendesk-launcher";

/* ---------------------------------------------------------------- */
/* 4. THIS PROJECT STYILING                                         */
/* ---------------------------------------------------------------- */

/* Highly suggested scaffolding, order, and naming conventions:     */

/* ---------------------------------------------------------------- */
/* 4.2 Basics:                                                       */
/* ---------------------------------------------------------------- */

@import "basics/_b_typography";
@import "basics/b_forms";
@import "basics/_b_buttons";

/* ---------------------------------------------------------------- */
/* 4.3 Components:                                                  */
/* ---------------------------------------------------------------- */
@import "components/c-lang-switcher";
@import "components/_c-cookiebar";
@import "components/_c-embed";
@import "components/_c-notification";
@import "components/_c-notfound";
@import "components/_c-loader";
@import "components/c-splash-welcome";
@import "components/c-nav-service";
@import "components/_c-badge";
@import "components/_c-section-header";
@import "components/c-event-note";
@import "components/c-event-program-table";
@import "components/c-event-row";
@import "components/c-card-abstract";
@import "components/c-dataview";
@import "components/c-conference-panel";
@import "components/c-concept-section";
@import "components/c-footer";
@import "components/c_abstract";
@import "components/c_abstract-text";
@import "components/c-partners-panel";
@import "components/c-sidebar-nav";
@import "components/c-page-sidebar";
@import "components/c-anchor-title";
@import "components/_c_columns";
@import "components/c-column-single";
@import "components/_c_page-headings";
@import "components/_c-invitation";
@import "components/_c_fullHeader";
@import "components/_c_matrix";
@import "components/_c_richtext";
@import "components/_c_cite";

@import "components/c-slider";
@import "components/c-question-answer";
@import "components/c-video-wide";
@import "components/c-press-doc";
@import "components/c-conference-video";
@import "components/c-chat-wee-block";

@import "components/c-modal";
@import "components/c-create-account-stepper";
@import "components/c-create-account-stepper";
@import "components/_c-delegation-form";
@import "components/_c-delegation-view";
@import "components/_c-navbar";
@import "components/c-profile-header";
@import "components/_c-menu-icon";
@import "components/_c-exhibition-map";
@import "components/_c-exhibition-slide";
@import "components/_c-exhibition-zimg";
@import "components/_c-exhibition-carousel";
@import "components/c-exhibition-splash";
@import "components/c-exhibition-video";
@import "components/c-text-modal";
@import "components/c-video-modal";
@import "components/_c-concert";
@import "components/_c-force-orientation";
@import "components/_c-zoomable";
@import "components/c-360-viewer";
@import "components/c-chat-wee-mobile-closer.scss";
@import "components/c-twin-cards";
@import "components/c-backtotop";


/* ---------------------------------------------------------------- */
/* 4.4Layouts:                                                     */
/* ---------------------------------------------------------------- */

body {
    margin:0;
    padding:0;
    background: rgb(4,30,64);
    background: radial-gradient(circle, rgba(4,30,64,1) 0%, rgba(4,30,64,1) 52%, rgba(22,22,22,1) 100%);
    background-attachment: fixed;
    //background-image: url("../images/temporary-bg.jpg");
    //background-repeat: no-repeat;
    //background-color: #081C37;
    //background-size: 100%;
    color: getColor('text');
    position: relative;
}

@import "layouts/l-main-content";
@import "layouts/l-header-navs";
@import "layouts/l-cms-page";
@import "layouts/l-home";
@import "layouts/l-registration";
@import "layouts/l-profile";
@import "layouts/l-profile-form";
@import "layouts/l-password-reset";
@import "layouts/l-thank-you";
@import "layouts/l-delegation";
@import "layouts/l-invitations";
@import "layouts/l-sidebar";
@import "layouts/l-contact";
@import "layouts/l-fixednavigation";
@import "layouts/l-exhibition";
@import "layouts/l-concert";
@import "layouts/l-home-tools";


/* ---------------------------------------------------------------- */
/* TEMPORARY:                                              */
/* ---------------------------------------------------------------- */

//temp-bg is used on mantainance as a figure>img tags
.temp-bg,
.main-cover-bg{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height:100%;
    display: block;
    img{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    &:after{
        content:'';
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height:100%;
        background: linear-gradient(0deg, rgba(0,0,0,.4), transparent);
    }
}
.video-bg{
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}
main#root{
    position: relative;
}
