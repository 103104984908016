.l-invitations {
  position:relative;
  box-shadow: $sectionsShadow;
  @extend %with-vertical-label;
  @include mainContainer();
  background: #364250;
  padding: rem(45);
  margin-top: $componentVerticalPadding;

  &:before {
    color: getColor('lightgray');
  }

}