.c-partners-panel{
    background: #D1D3D3;
    color: getColor('text-dark');
    position: relative;
    box-shadow: $sectionsShadow;
    .partners{
        &__wrapper{
            display: flex;
            flex-flow: column;
            align-items: center;
            text-align: center;
            padding: rem(70);
            position: relative;
        }
        &__extra-bg{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #DADBDB;
            clip-path: polygon(0 0,90% 0,70% 100%,0 100%);
        }
        &__title{
            @extend %title;
        }
        &__description{
            @extend %description;
        }
        &__logos{
            width: 100%;
            .glide{
                min-height: rem(100);
                margin: rem(20) 0;
                .glide__slides{
                    //align-items: center;
                }
                .glide__slide{
                    height: auto;
                    display: flex;
                    align-items: center;
                }
            }
        }
        &__cta{
            margin-top: rem(30);
        }
    }
}

@include responsiveTo('md'){
    .c-partners-panel{
        .partners{
            &__wrapper{
                padding: rem(50) rem(20);
            }
            &__cta{
                margin-top: rem(40);
            }
        }
    }
}



/* --------------------------------------------------- */
/* ------- scroll animation -------------------------- */
/* --------------------------------------------------- */


/* ------- scroll animation: transitions & delays ---- */


.partners__wrapper > div {
    opacity: 0;
    transform: translateY(-1rem);
    @for $i from 1 to 5 {
        &:nth-child(#{$i}) {
            transition: all 1s ease ($i) * 0.3s;
        }
    }
}

.in-view {
    .partners__wrapper > div {
        opacity: 1;
        transform: translateY(0rem);
    }
}
/* ------- scroll animation: out properties ---------- */

.out-view {
    .partners__wrapper > div  {
        opacity: 0;
    }
}
