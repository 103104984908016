.c-exhibition-zimg {
  //border: solid 2px aqua;

  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;

  &--zoomed {
    text-align: unset;
  }

  &__image {
    width: auto;
    height: 100%;
    object-fit: contain;
      cursor:none;
  }
}

