.c-event-program-table{
    --pad-wrapper: #{rem(100)} #{rem(20)} #{rem(20)};
    --size-day: #{rem(100)};
    --size-month: #{rem(44)};
    --size-weekday: #{rem(30)};
    --size-title: #{rem(20)};
    --size-title-sidebar: #{rem(60)};
    .program{
        &__wrapper{
            position: relative;
            padding: var(--pad-wrapper);
            height: 100%;
            display: flex;
            flex-flow: column;
        }
        &__schedule{
            position: absolute;
            top: rem(-50);
            left: rem(40);
        }
        &__weekday{
            font-size: var(--size-weekday);
            text-align: center;
            text-transform: capitalize;
        }
        &__date{
            color: getColor('secondary');
            display:flex;
            align-items: flex-end;
            line-height: 1;
            font-family: $font-family-secondary;
            .day{
                font-size: var(--size-day);
                line-height: .85;
            }
            .month{
                font-size: var(--size-month);
                &:before{
                    content: '/';
                }
            }
        }
        &__table{
            &__title{
                color: getColor('text-gray');
                text-align: center;
                font-size: var(--size-title);
                font-weight: $font-semi-bold;
                text-transform: uppercase;
                margin-bottom: rem(50);
            }
        }
        &__cta{
            flex: 1;
            display: flex;
            flex-flow: column;
            justify-content: flex-end;
            align-items: center;
            width: 60%;
            margin: rem(30) auto 0;
            [class^='btn-']{
                margin-bottom: rem(10);
            }
        }
    }
    &.in-sidebar{
        --pad-wrapper: 0 0 #{rem(30)};
        color: getColor('dark');
        background: getColor('lightsmoke');
        .program{
            &__schedule{
                position: static;
            }
            &__close{
                color: getColor('dark');
                align-self: flex-start;
                cursor: pointer;
            }
            &__table__title{
                display: grid;
                grid-template-columns: auto 1fr rem(30);
                gap: 20px;
                padding: rem(30);
                align-items: center;
                justify-items: flex-start;
                position: sticky;
                top: 0;
                z-index: $z-i-main;
                background: getColor('lightsmoke');
                &>span{
                    font-size: var(--size-title-sidebar);
                    font-family: $font-family-secondary;
                    text-transform: capitalize;
                    color: getColor('primary');
                }
            }
        }

    }
}

@include responsiveTo('md'){
    .c-event-program-table{
        --size-day: #{rem(40)};
        --size-month: #{rem(25)};
        --size-weekday: #{rem(20)};
        --size-title: #{rem(20)};
        --size-title-sidebar: #{rem(30)};
        --pad-wrapper: #{rem(70)} #{rem(20)} #{rem(20)};
        .program{
            &__schedule{
                top: rem(-30);
            }
            &__table{
                &__title{
                    margin-bottom: rem(30);
                }
            }
            &__cta{
                width: auto;
            }
        }
    }
}
