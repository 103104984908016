// @import '~normalize.css';

/* $ComponentPadding: Distance of contents from page: as the main wrapper margin set by _u_grid */
$ComponentPadding:  calc( var(--main-container-pad-x) * 1px);
$ComponentverticalPadding: $siteComponentsMargin;

.wip-abstract {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: $ComponentverticalPadding;
  padding-bottom: $ComponentverticalPadding;
  * {
    box-sizing: border-box;
  }

  &.no-margin {
    margin-bottom: 0;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    &.right {
      flex-direction: row-reverse;
    }
    &--bright {
      color: getColor('inverted');
    }
  }
  //end __item

  // Image wrapper into flex container
  &__imagewrp {
    min-width: 0px; /* <--- to avoid slick problems! */
    flex: 1 1 50%;
    &__image {
      margin: 0;
      width: 100%;
      img {
        width: 100%;
      }
      &.fit {
        padding: 0em 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
  // end __imagewrp

  // Content into flex container
  &__contentwrp {
    flex: 1 1 50%;
    padding: 0; /* override from alignment classes */
    &__pretitle {
      @extend %pretitle;
    }
    &__title {
      @extend %title;
    }
    &__subtitle {
      @extend %subtitle;
    }
    &__text {
      padding-top: rem(15);
      p:last-child {
        margin-bottom: 0;
      }
    }
    //CTA
    &__cta {
      display: flex;
      margin-top: rem(70);
      button + button,
      button + input[type="button"],
      button + a,
      input[type="button"] + button,
      input[type="button"] + input[type="button"],
      input[type="button"] + a,
      a + button,
      a + input[type="button"],
      a + a {
        margin-left: 0.5em;
      }
    }
  }
}

//end component

/* ------------- smartphone and tablet ------------- */

@include responsiveTo(lg) {

  .wip-abstract {
    padding-top: 0%;
    padding-bottom: $ComponentPadding;
    &__imagewrp {
      flex: 0 0 auto;
      @include mainContainer();
      margin-top: $ComponentPadding;
    }
    &__contentwrp {
      flex: 0 0 auto;
      @include mainContainer();
      padding: 2rem 0 0 0;
    }

    &__item {
      .slick-dots {
        bottom: 0;
      }
      .wip-abstract__contentwrp {
        font-size: 0.9em;
      }
      &.wip-abstract__item--overflow {
        .wip-abstract__imagewrp {
          flex:  1 1 100%;
          margin-top: 0;
        }
      }
    }
  }
}

/* ------------- desktop ------------- */

@include responsiveFrom(lg) {
  .wip-abstract {
    &__item {
      @include mainContainer();
      .wip-abstract__contentwrp {
        padding-left: $ComponentPadding;
      }
      &.wip-abstract__item--right {
        flex-direction: row-reverse;
        .wip-abstract__contentwrp {
          padding-right: $ComponentPadding;
          padding-left: 0;
        }
      }
      &.wip-abstract__item--overflow {
        width: 100%;
        .wip-abstract__contentwrp {
          padding-right: $ComponentPadding;
        }
        &.wip-abstract__item--right  {
          .wip-abstract__contentwrp {
            padding-left: $ComponentPadding;
          }
        }
      }
    }
  }
}


/* --------------------------------------------------- */
/* ------- scroll animation -------------------------- */
/* --------------------------------------------------- */


/* ------- scroll animation: transitions & delays ---- */

.wip-abstract__imagewrp__image {
  transition: all 0.5s ease;
}

.wip-abstract__contentwrp {
  > div {
    @for $i from 1 to 10 {
      &:nth-child(#{$i}) {
        transition: all 1s ease ($i) * 0.2s;
      }
    }
  }
}

/* ------- scroll animation: out properties ---------- */

.out-view {
  .wip-abstract__imagewrp__image {
    transform: scale(0.9);
    opacity: 0;
  }
  .wip-abstract__contentwrp {
    > * {
      opacity: 0;
    }
  }
}
