.c-textarea{
  @extend %c-field;
  height: auto;
  textarea{
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    color: getColor('text');
    margin: 1rem 0;
    min-height: 80px;
    &:-internal-autofill-selected {
      background-color: transparent;
    }
  }

}
