
.c-delegation-view {
  position: relative;

  &__content {
    @extend %with-vertical-label;
  }

  &__section {
    margin-bottom: 2rem;
    flex-grow: 1;
    padding: rem(45);
    background-color: darken(getColor('tertiary'), 10%);
    box-shadow: $sectionsShadow;
    &:first-child {
      background-color: getColor('tertiary');
      margin-right:1rem;
    }
    &__data {
      &__row {
        display: grid;
        grid-template-columns: 50% 50%;
        &__col {
        }
      }
    }
  }

  &__section-label {
      @extend .tag-title;
  }
}

@include responsiveFrom(lg) {
  .c-delegation-view {

    &__content {
      display: flex;
      justify-content: space-between;
    }

    &__section {
    }
  }
}

