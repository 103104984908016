.c-section-header {
  margin-bottom: $siteComponentsMargin;

  &__name {
    position: relative;
    font-size: rem(32);
  }

  &__data {
    &__label {
      text-transform: uppercase;
      padding-right: 1rem;
    }
    &__value {
      font-weight: 700;
      text-transform: uppercase;
      padding-right: 2rem;
      span {
        font-weight: 500;
      }
    }
  }
}


@include responsiveFrom(lg) {
  .c-section-header {
    display: flex;
    align-items: center;
    &__name {
      $rowWidth: 3rem;
      $rowDistance: 1rem;
      padding-right: $rowDistance + $rowWidth;
      margin-right: $rowDistance;
      &::before {
        position: absolute;
        top:50%;
        right: 0;
        border: 1px solid getColor('lightgray');
        content: '';
        width: $rowWidth;
      }
    }
  }
}

@include responsiveTo(lg) {
  .c-section-header {
    &__name {
      display: block;
      padding-bottom: 1rem;
    }
    &__data {
      &__value {
        display: block;
        padding-bottom: 1rem;
      }
    }
  }
}