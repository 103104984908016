.c-invites {
  &__card {
    padding: 1.5rem;
    background: getColor('darkblue');
    border: 2px solid white;
    border-radius: 1em;
    margin-top:1rem;

    &__status {
      text-transform: uppercase;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 1.2em;
      .dataview {
        margin: 0;
      }
      [class*="btn"] {
        color: getColor('text');
        padding: 0;
        min-width: 0;
        min-height: 0;
      }
    }

    &__label {
      text-transform: uppercase;
      padding-bottom: 1.5rem;
      font-weight: $font-semi-bold;
      color: getColor('lightgray');
    }

    &__content {

    }

    &__actions {
      &__secondary {
        display: flex;
        justify-content: center;
        padding-top: 1rem;
        flex-wrap: wrap;
        [class*="btn"] {
          color: getColor('text');
          font-size: 0.8em;
        }
      }
    }
  }
}


@include responsiveFrom(lg) {
  .c-invites {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-left: -1rem;
    padding-top: 2rem;
    box-sizing: border-box;

    &__card {
      $margin: 1rem;
      flex: 0 0 calc(33.333333% - 2*#{$margin});
      max-width: calc(33.333333% - 2*#{$margin});
      margin:$margin;
    }
  }

}