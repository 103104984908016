.c-profile-header {
  @include mainContainer();
  margin-top: rem(80);
  margin-bottom: rem(80);

  &__intro {
    @extend %pretitle;
  }

  &__heading {
    @extend %big-title;
    margin-top:0;
    margin-bottom:0;
    padding-top: 0;
  }

  &__subheading {
  }

  &__badge {
    @extend .c-badge;
    margin-left: 1rem;
  }
}