.c-page-sidebar{

    .page-sidebar{
        &__wrapper{
            display:flex;
            flex-flow: column;
            background: #29343A;
            position: -webkit-sticky;
            position: sticky;
            // Note: var --h is declared into l-fixednavigation to pad body for navigation purposes
            top: calc(var(--h) * 1px);
        }
        &__item{
            padding: rem(20) rem(30);
            border-bottom: 2px solid #404040;
            &:last-child{
                border-bottom: none;
            }
            &.active{
                color: getColor('secondary');
            }
        }
    }
}
