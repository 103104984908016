.c-exhibition-map {
    --dot-size: #{rem(22)};
  position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: rem(40);
    align-items: center;
  &__close {
    position: absolute;
    top: $mainContainerPadY;
    right: $mainContainerPadY;
    cursor: pointer;
    color: getColor('text');
  }

    &__label{
        color: getColor('lightgray');
        font-size: rem(80);
        font-weight: $font-thin;
        text-transform: uppercase;
        text-align: center;
    }
    &__content{
        margin-right: rem(80);
        .scrollbar-container{
            display: grid;
            grid-template-columns: 1fr;
            gap: rem(10);
        }
    }

  &__row {
    //display: flex;
    //flex-flow: row nowrap;
    //justify-content: center;
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
  }

  &__name {
    flex: 1;
      font-size: rem(14);
      font-weight: $font-semi-bold;
      text-transform: uppercase;
      cursor: pointer;
    &--current {
      color: getColor('secondary');
    }
  }

  &__slides {
    flex: 2;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  &__dot-wrapper {
    margin: 0 rem(5);
  }

  &__dot {
    //padding-top: 100%;
    width: var(--dot-size);
    height: var(--dot-size);
    border: solid 2px getColor('lightgray');
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
    &--intro {
      background-color: getColor('lightgray');
    }

    &--current {
      border-color: getColor('secondary');
      background-color: getColor('secondary');
    }
  }
}

@include responsiveTo('lg'){
    $topgap:4rem;
    .c-exhibition-map{
        --dot-size: #{rem(20)};
        grid-template-columns: 1fr;
        height: 100vh;
        padding: $topgap 0 rem(20);
        align-items: flex-start;
        justify-content: flex-start;
        &__label{
            display:none;
        }
        &__content{
            height: calc(100vh - #{$topgap} );
            width: min(#{rem(500)},90vw);
            margin: 0 auto;
            padding-bottom: 1rem;
        }
        &__row{
            grid-template-columns: 1fr;
            padding-bottom: 0.5em;
        }
        &__name{
            font-size: rem(12);
            padding-bottom: 1em;
        }
    }
}
