//@import '~normalize.css';

/* $ComponentPadding: Distance of contents from page: as the main wrapper margin set by _u_grid */
$ComponentPadding:  calc( var(--main-container-pad-x) * 1px);
$ComponentverticalPadding: $siteComponentsMargin;


.wip-columns {
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% center;
  display: flex;
  align-items: center;
  a {
    color: getColor('primary');
  }

  &.no-margin {
    margin-bottom: 0;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include mainContainer();
    padding: $ComponentverticalPadding 0;
    &__title {
      padding-top: $ComponentverticalPadding/2;
      @extend %title;
      padding-bottom: $ComponentverticalPadding;
    }
    .wip-columns__columns {
      //display: flex;
      //flex-wrap: wrap;
      //justify-content: space-between;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: rem(30);
    }
  }
}


/* ------------- smartphone and tablet ------------- */


@include responsiveTo(lg) {
  .wip-columns {
    &__item {
      .wip-columns__columns {
        grid-template-columns: 1fr;
          gap: rem(20);
        .c-column-single {
          flex: 1 1 100%;
          padding: calc(#{$ComponentPadding} / 2) 0 ;
          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}


/* ------------- desktop ------------- */

@include responsiveFrom(lg) {

  .wip-columns {
    min-height: 20rem;
    &__item {
      .wip-columns__columns {
        .wip-columns__column {
          //flex: 1 1 0px; // 0px for ie
          //padding: 0 calc(#{$ComponentPadding} / 2);
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }

}



/* --------------------------------------------------- */
/* ------- scroll animation -------------------------- */
/* --------------------------------------------------- */


/* ------- scroll animation: transitions & delays ---- */

.wip-columns__column__imagewrp__image {
  transition: all 0.5s ease;
}


@for $i from 1 to 10 {
  .wip-columns__column:nth-child(#{$i}) {
    transition: all 0.8s ease ($i) * 0.15s;
  }
}

/* ------- scroll animation: out properties ---------- */

.out-view {
  .wip-columns__column__imagewrp__image {
    transform: scale(0.9);
    opacity: 0;
  }
  .wip-columns__column {
    opacity: 0;
    transform: translateY(2em);
  }
}
