.c-exhibition-carousel {
    --grid-template-cols: 1fr;
    --panel-cols: 1/-1;
    --controls-w: #{rem(45)};
  height: 100%;
  //display: flex;
  //flex-flow: row nowrap;
  //align-items: stretch;
  //justify-items: center;
    display: grid;
    grid-template-columns: var(--grid-template-cols);
    overflow: hidden;

  &__control {
    height: 100%;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    //justify-items: center;
    //width: rem(40);
    width: 100%;
      justify-content: center;
    &--disabled {
      cursor: auto;
    }
  }

  &__panel {
      grid-column: var(--panel-cols);
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
      width: 100%;

      overflow: hidden;
  }

  &__mask {
    flex: 1;
    //position: relative;
    overflow: hidden;

    &__grid {
      height: 100%;

      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      align-content: center;

        transition: transform .3s ease-in-out;
    }
  }

  &__item {
    min-width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2px;
    img {
      object-fit: contain;
      height: 100%;
    }
  }

  &__indicator {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  &__dot {
      $dot-size: rem(15);
    border-radius: 50%;
    cursor: pointer;
    width: $dot-size;
    height: $dot-size;
    margin: 5px;

    border: solid 1px getColor("darkgrey");
    background-color: getColor("lightgrey");

    &--current {
      border: solid 1px getColor("darkblue");
      background-color: getColor("secondary");
    }
  }
    //when multiple items
    &.has-multiple{
        --grid-template-cols: var(--controls-w) 1fr var(--controls-w);
        --panel-cols: 2/3;
    }
}

@include responsiveTo('lg'){
    .c-exhibition-carousel{
        --controls-w: #{rem(20)};
    }
}
