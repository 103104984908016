.c-anchor-title{
    margin-bottom: rem(70);
    color: getColor('secondary');
    --display-line-left: none;
    --display-line-right: block;
    --title-align:left;

    .anchor{
        &__title{
            @include mainContainer();
            font-size: rem(40);
            font-family: $font-family-secondary;
            display: flex;
            align-items: center;
            text-align: var(--title-align);
            span{
                flex: 0 0 auto;
            }
            &:before,
            &:after{
                border-top: 2px solid currentColor;
            }
            &:before{
                display:var(--display-line-left);
                content: '';
                flex: 1;
                margin-right: 20px;
            }
            &:after{
                display: var(--display-line-right);
                content: '';
                flex: 1;
                margin-left: 20px;
            }
        }
        &__line{
            display: none;
            flex: 1;
            border-top: 2px solid currentColor;
        }
    }
    &.align{
        &--center{
            --display-line-left: block;
            --display-line-right: block;
            --title-align: center;
        }
        &--right{
            --display-line-left: block;
            --display-line-right: none;
            --title-align: right;
        }
    }
}

@include responsiveTo('lg'){
    .c-anchor-title{
        .anchor{
            &__title{
                flex-flow: column;
                font-size: rem(24);
            }
        }
    }
}
