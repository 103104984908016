.c-modal {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $z-i-modal;
  color: getColor('text-dark');
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    &::before {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height:100%;
    background: black;
    opacity: 0.4;
    content:'';
  }
  &__wrapper {
    position: relative;
    background: white;
    padding: 2rem;
    box-shadow: 0px 0px 88px 2px rgba(0,0,0,0.8);
    border-radius: 3px;
  }
  &__closer {
    position: absolute;
    top:0;
    right: 0;
    color: black;
    padding: 1rem;
    cursor: pointer;
  }
  &__heading {
    @extend %title;
    padding: 0 0 2.5rem 0;
  }
}
