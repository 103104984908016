.c-splash-welcome{
    position: relative;
    .splash{
        &__foreground{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #051E3F;
            z-index: 999;
            opacity: 1;
            transition:all 3s ease-in-out;
        }
        &__wrapper{
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding: 2% 0 1% 0;
            position: relative;
            //Lang Switcher component
            .c-lang-switcher{
                position: absolute;
                top: 5vh;
                right: 5vh;
            }
        }
        &__logo{
            margin-bottom: 4%;
        }
        &__central{
            text-align: center;
            &__pretitle{
                font-size: 1.2rem;
            }
            &__title{
                @extend %big-title;
                color: getColor('secondary');
            }
            &__subtitle{
                font-size: 1.2rem;
                color: getColor('text');
            }
            &__extra-subtitle{
                font-size: 1.3rem;
                font-family: $font-family-base;
                text-transform:uppercase;
                margin-top: rem(60);
            }
        }
        &__bottom{
            display: flex;
            flex-flow: row;
            align-items: center;
            cursor: pointer;
            border: 1px solid getColor('darkprimary');
            border-radius: 0.5em;
            flex-wrap: wrap;
            box-shadow:  0px 0px 30px 0px rgba(0, 0, 0, 0.5);
            background: rgba(34, 46, 61, 0.3);
            min-width:rem(800);
            &__block {
                text-align: center;
                padding: 1.5rem 2rem;
                font-size: 0.8em;
                width: 50%;
                button{
                    margin: rem(20) auto 0 0;
                }
                &__title {
                    font-size: 1.4em;
                    margin-bottom: 0.25em;
                }
            }
        }
    }
    &.loaded{
        .splash{
            &__foreground{
                opacity: 0;
                pointer-events: none;
            }
        }
    }
}


@include responsiveFrom('md'){
    .c-splash-welcome {
        height: 100vh;
        .splash {
            &__bottom {
                margin-top: 2%;
            }
        }
    }
    .splash__bottom__block + .splash__bottom__block {
        border-left: 1px solid getColor('darkprimary');
    }
}

@include responsiveTo('md'){
    .c-splash-welcome{
        .splash{
            &__wrapper{
                padding: rem(20);
                .c-lang-switcher{
                    top: 4vh;
                    right: 2vh;
                }
            }
            &__logo{
                margin-bottom: rem(30);
            }
            &__central{
                &__title{
                    font-size: rem(30);
                }
                &__subtitle{
                    font-size: rem(16);
                }
                &__extra-subtitle{
                    font-size: rem(14);
                }
            }
            &__bottom{
                margin-top: 2rem;
                flex-flow: column;
            }
        }
    }

    .splash__bottom__block + .splash__bottom__block {
        border-top: 1px solid getColor('darkprimary');
    }
}

.splashed {
    .c-footer, .c-navbar {
        display: none;
    }

}

/* ------------------------------------------------- */
/* ---------------- ANIMATION ---------------------- */
/* ------------------------------------------------- */
.c-splash-welcome .splash__wrapper {
    $speed : 1s;
    > * {
        animation-name: fading;
        animation-duration: 1s;
        animation-fill-mode: backwards;
        &.splash__logo {
            animation-delay: 0s;
        }
        &.splash__central {
            animation-delay: $speed*0.8;
        }
        &.splash__bottom {
            animation-delay: $speed*2;
        }
    }
    .splash__central {
        &__title,
        &__subtitle,
        &__extra-subtitle{
            overflow: hidden;
            span {
                display: block;
                transform-origin: 0 100%;
                animation-name: rotateIn;
                animation-duration: 1s;
                animation-fill-mode: backwards;
            }
        }
        @for $i from 1 to 35 {
            > *:nth-child(#{$i}) {
                span {
                    animation-delay: ($i+1) * 0.5s;
                }
            }
        }
    }

}



@keyframes fading {
    0%   {
        opacity: 0;
        transform: translateY(-4rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes rotateIn {
    0%   {
        transform: rotate(8deg) translateY(8rem);
    }
    100% {
        transform: rotate(0deg) translateY(0rem);
    }
}
