.c-cookiebar {
  position: fixed;
  left: 0;
  width: 100%;
  background: getColor('darkblue');
  font-size: 0.8rem;
  bottom: -100%;
  z-index: $z-i-header;

  &--accepted {
    display: none;
    bottom: -100%;
  }
  &--notaccepted {
    bottom: 0;
  }

  &__inner {
    @include mainContainer();
    padding: 2em;
  }

  &__abstract {
    flex-grow: 1;
  }

}

@include responsiveFrom('md') {
  .c-cookiebar {
    &__inner {
      display: flex;
      justify-content: space-between;
    }
    &__action {
      display: flex;
      justify-content: space-between;
      [class*="btn"] {
        margin-left: 1em;
      }
    }
  }
}

@include responsiveTo('md') {
  .c-cookiebar {
    &__action {
      margin-top: 1rem;
      [class*="btn"] {
        display: block;
        width: 100%;
      }
      [class*="btn"] + [class*="btn"] {
        margin-top: 1rem;
      }
    }
  }
}