.c-concept-section{
    background: #475459;
    position: relative;
    box-shadow: $sectionsShadow;
    .concept{
        &__extra-bg{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: getColor('darkgray');
            clip-path: polygon(10% 0, 100% 0, 100% 100%,30% 100%);
        }
        &__wrapper{
            position: relative;
            padding: rem(60);
            display: grid;
            gap: rem(30);
            grid-template-columns: 1fr 2fr;
            align-content: center;
            justify-items: center;
            align-items: center;
        }
        &__image{
            border-radius: 50%;
            overflow: hidden;

            figure,img{
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        &__title{
            @extend %title;
        }
        &__description{
            @extend %description;
        }
        &__cta{
            margin-top: rem(40);
            display: flex;
            align-items: center;
        }
    }
}

@include responsiveTo('md'){
    .c-concept-section{
        .concept{
            &__extra-bg{
                clip-path: polygon(0 0, 100% 0, 100% 100%,100% 100%);
            }
            &__wrapper{
                grid-template-columns: 1fr;
            }
        }
    }
}

/* --------------------------------------------------- */
/* ------- scroll animation -------------------------- */
/* --------------------------------------------------- */


/* ------- scroll animation: transitions & delays ---- */


.concept__content > div {
    opacity: 0;
    transform: translateY(-1rem);
    @for $i from 1 to 5 {
        &:nth-child(#{$i}) {
            transition: all 1s ease ($i) * 0.3s;
        }
    }
}

.concept__image {
    transform: scale(0.8);
    transition: transform 1s ease;
}

.in-view {
    .concept__content > div {
        opacity: 1;
        transform: translateY(0rem);
    }
    .concept__image {
        transform: scale(1);
    }
}


/* ------- scroll animation: out properties ---------- */

.out-view {
    .concept__content > div  {
        opacity: 0;
        transform: translateY(0rem);
    }
}
