.c-footer{
    padding: rem(100) 0 rem(30) 0;
    margin-bottom: rem(90);
    --footer-template: 1fr 3fr 1fr 1fr;
    --box-template: repeat(1,1fr);
    --font-size-links: #{rem(14)};
    position: relative;
    .footer{
        &__wrapper{
            @include mainContainer();
            display:grid;
            grid-template-columns: var(--footer-template);
            border: 4px solid getColor('lightgray');
        }
        &__box {
            border-right: 2px solid getColor('lightgray');
            display:grid;
            grid-template-columns: var(--box-template);
            align-items: center;
            color: getColor('lightgray');
            padding: 0.8rem;
            font-weight: $font-semi-bold;
            a {
                color: currentColor;
                text-transform: uppercase;
                text-decoration: none;
                justify-self: left;
                padding: rem(10) 0;
                font-size: var(--font-size-links);
                &:hover{
                    color: getColor('secondary');
                }
            }
            &--primary {
                color: getColor('text');
                grid-template-columns: repeat(3,1fr);
            }
            &--secondary {
                a {text-transform: unset;}
            }
            &:last-child{
                border:none;
            }
        }
        &__ehea{
            color: getColor('lightgray');
            &__title{
                font-size: 1.2em;
                padding-bottom: 0.5rem;
            }
            &__copy{
                font-weight: $font-regular;
                color: getColor('text');
                font-size: 0.8em;
            }
        }
    }
}

@include responsiveTo('xl'){
    .c-footer{
        --footer-template: repeat(4,1fr);
        --box-template: 1fr;
        .footer{
            &__box{
                &--primary {
                    grid-template-columns: repeat(1,1fr);
                }
            }
        }
    }
}

@include responsiveTo('lg'){
    .c-footer{
        --footer-template: repeat(2,1fr);
        .footer{
            &__wrapper{
                //border:3px solid getColor('darkgray');
            }
            &__box{
                border:1px solid getColor('lightgray') !important;
            }
        }
    }
}

@include responsiveFrom('lg'){
    .c-footer {
        .footer {
            &__box {
                padding: 1.4em;
                &--address {
                    width: rem(300);
                }
            }
        }
    }
}
@include responsiveTo('md'){
    .c-footer{
        --font-size-links: #{rem(12)};
        --footer-template: 1fr;
        .footer{
            &__ehea{
                text-align: center;
            }
        }
    }
}
