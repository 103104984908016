
.dataview {
  margin: 0 0 1.2em 0;


  &--inline {
    display: flex;
    align-items: center;
    .dataview__label {
      padding: 0em 1rem 0em 0em;
    }
  }

  &__label {
    font-size: 0.8em;
    padding: 0em 0em 0.5em 0em;
    display:block;
  }
  &__value {

  }
}