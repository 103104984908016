.l-input-group {
    &__content {
        display: flex;
        justify-content: space-between;
        > * {
            min-width: 0;
            width:50%;
            &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-right: 1px solid getColor('lightgray');
            }
            &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
}