$ComponentverticalPadding: $siteComponentsMargin;

.wip-matrix {
  width:100%;
  .wip-matrix__item {
    padding-top: $ComponentverticalPadding;
    position: relative;
    @include mainContainer();
    .wip-matrix__title {
      padding-top: $ComponentverticalPadding/2;
      padding-bottom: $ComponentverticalPadding;
      @extend %title;
      text-align: center;
    }
    .wip-matrix__wrapper {
      position:relative;
      margin: 0 auto;
      display:flex;
      flex-wrap:wrap;
      justify-content: flex-start;
      .wip-matrix__wrapper__image {
        text-align:center;
        padding:1em;
        flex-grow:1;
        img {
          min-width: 150px;
        }
      }
    }
  }
}


/* --------------------------------------------------- */
/* ------- scroll animation -------------------------- */
/* --------------------------------------------------- */


/* ------- scroll animation: transitions & delays ---- */

.wip-matrix__wrapper__image {
  overflow: hidden;
}

@for $i from 1 to 50 {
  .wip-matrix__wrapper__image:nth-child(#{$i})  {
    img {
      transition: all 1.5s ease ($i) * 0.15s;
    }
  }
}

/* ------- scroll animation: out properties ---------- */

.out-view {
  .wip-matrix {
    .wip-matrix__item {
      .wip-matrix__wrapper {
        .wip-matrix__wrapper__image {
          img {
            transform: scale(0.9);
            opacity: 0;
          }
        }
      }
    }
  }
}
