.c-navbar {
  display: flex;
  &__logo {
    display: flex;
    align-items: flex-start;
    padding: $mainContainerPadY $mainContainerPadX;
    img {
      width: rem(100);
    }
  }
  &__container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__menu {
    &__mainlinks {
      position: relative;
      &:before {
        position: absolute;
        border: 1px solid getColor('lightgray');
        content: '';
      }
    }
    &__item {
      color: getColor('text');
      a {
        position: relative;
        display: block;
        color: getColor('text');
        padding: 1em 0;
        transition: all 0.3s cubic-bezier(0.3, 0.8, 0.6, 1);
        &:hover {
          color: getColor('secondary');
          transform: translateY(-0.25em);
          &:after {
            width: 100%;
            color: getColor('secondary');
            border-bottom:1px solid getColor('secondary');
          }
        }
        &:after {
          position: absolute;
          transition: all 0.3s cubic-bezier(0.3, 0.8, 0.6, 1);
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          border-bottom:1px solid transparent;
          content: '';
        }
      }
    }
  }
  &__mobiletrigger {
    display: none;
  }
}

@include responsiveTo(xl) {
  .c-navbar {
    &__mobiletrigger{
      position: absolute;
      top: $mainContainerPadX;
      right: $mainContainerPadX;
      z-index: $z-i-modal + 1 ;
      display: block;
    }
    &__container {
      position: fixed;
      z-index: $z-i-modal ;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      flex-direction: column-reverse;
      justify-content: center;
      padding-top: 2rem;
      &:before {
        transition: all 0.8s cubic-bezier(.67,.01,.55,.8);
        position: absolute;
        z-index: 1;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background: rgb(4,30,64);
        background: radial-gradient(circle, rgba(4,30,64,1) 0%, rgba(4,30,64,1) 52%, rgba(22,22,22,1) 100%);
        background-attachment: fixed;
        opacity: 0;
        content: '';
      }
    }
    &__menu {
      position: relative;
      z-index: 2;
      $itemPadding: 0.6rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      &__mainlinks {
        width: 100%;
        padding-bottom: 2*$itemPadding;
        margin-bottom: 2*$itemPadding;
        .c-navbar__menu__item {
          font-weight: $font-bold;
          width: 100%;
          text-align: center;
        }
      }

      &__item {
        padding: $itemPadding 0;
      }
    }
  }
}

@include responsiveFrom(xl) {
  .c-navbar {
    display: flex;
    &__logo {
      img {
        width: rem(120);
      }
    }
    &__menu {
      $itemPadding: 1.4rem;
      display: flex;
      align-self: flex-start;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
      margin-bottom: 3rem;

      &__mainlinks {
        display: flex;
        align-items: center;
        justify-content: space-between;
        $rowWidth: 3rem;
        padding-right: $rowWidth + 2*$itemPadding;
        &::before {
          width: $rowWidth;
          top: 50%;
          right: $itemPadding;
        }
      }

      &__item {
        padding: 0 $itemPadding;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: $font-semi-bold;
        font-size: rem(14);
        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
}

@include responsiveFrom(xxl) {
  .c-navbar {
    &__logo {
      img {
        width: rem(160);
      }
    }
    &__menu {
      $itemPadding: 2.4rem;
      &__item {
        padding: 0 $itemPadding;
      }
    }
  }
}




/* ------------------------------------------------- */
/* ---------------- ANIMATION ---------------------- */
/* ------------------------------------------------- */



@include responsiveTo(xl) {
  .c-navbar {
    .c-navbar__container {
      /* ---------- initial state ------- */
      // hide panel
      transform: translateX(-100vw);
      &:before {
        // the panel background
        transition: opacity 0.4s ease-in;
        opacity: 0;
      }
      .c-navbar__menu__mainlinks:before {
        // the mainlinks row
        transition: all 1.5s cubic-bezier(.22, .89, .75, .98);
        $rowWidth: 0%;
        width: $rowWidth;
        bottom: 0;
        left: calc(50% - #{$rowWidth/2});
      }
      /* ---------- final state ------- */
      &--active {
        // show panel
        transform: translateX(0);
        &:before {
          opacity: 0.95;
        }
        .c-navbar__menu__mainlinks:before {
          $rowWidth: 20%;
          width: $rowWidth;
          bottom: 0;
          left: calc(50% - #{$rowWidth/2});
        }
        .c-navbar__menu__item {
          animation-name: fromLeft;
          animation-duration: 0.5s;
          animation-fill-mode: backwards;
          @for $i from 1 to 7 {
            &:nth-child(#{$i}) {
              animation-delay: ($i) * 0.2s;
            }
          }
        }
        .c-nav-service__item {
          animation-name: fromLeft;
          animation-duration: 0.5s;
          animation-fill-mode: backwards;
          @for $i from 1 to 7 {
            &:nth-child(#{$i}) {
              animation-delay: ($i * 0.2) + 1s;
            }
          }
        }
        .c-nav-service__item--icons {
          animation-name: fromLeft;
          animation-duration: 0.5s;
          animation-delay: 1s;
        }
      }
    }
  }
}

@keyframes fromLeft {
  0%   {
    opacity: 0;
    transform: translateX(-4rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}