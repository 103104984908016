.c-text-modal{
    --close-size: 50px;
    --tmodal-title-size: #{rem(40)};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: rem(20);
    background: rgba(0,0,0,.7);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: $z-i-modal;
    overflow:hidden;
    .text-modal{
        &__wrapper,
        &__head{
            @include mainContainer();
            position: relative;
            display: flex;
            flex-flow: column;
        }
        &__wrapper{
            height: 85%;
            overflow:auto;
        }
        &__close{
            width: var(--close-size);
            height: var(--close-size);
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: flex-end;
            margin-bottom: rem(10);
            cursor: pointer;
            padding: 5px;
            border: 2px solid currentColor;
            border-radius: 50%;
            line-height: 0;
        }
        &__title{
            order: 1;
            color: getColor('lightgray');
            font-size: var(--tmodal-title-size);
            font-weight: $font-thin;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: rem(20);
        }
        &__content{
            column-count: auto;
            column-width: max(#{rem(320)},#{rem(400)});
            border-left: 1px solid currentColor;
            border-right: 1px solid currentColor;
            padding: rem(20);
            font-size: rem(14);
            line-height: 1.6;
            letter-spacing: 1px;
            p{
                margin:0 0 rem(10);
            }
        }
        &__author,
        &__author-role{
            font-size: rem(14);
        }
        &__author{
            margin-top: rem(40);
        }
        &__author-role{
            color: getColor('secondary');
            font-style: italic;
        }
        &__intro-modal{


        }
    }
}

@include responsiveTo('xxl'){
    .c-text-modal{
        .text-modal{
            &__wrapper{
                height: 80%;
            }
            &__content{
                line-height: 1.7;
            }
        }
    }
}

@include responsiveTo('md'){
    .c-text-modal{
        --close-size: #{rem(40)};
        --tmodal-title-size: #{rem(28)};
    }
}
