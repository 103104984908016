.c-conference-panel{
    position: relative;
    .ev-live{
        color: getColor('secondary');
    }
    .ev-label{
        background: getColor('red');
        margin-right: rem(5);
        padding: rem(5) rem(10);
    }
    .ev-time{
        margin: 0 rem(10);
    }
    .conf-panel{
        &__bg{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            img{
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__wrapper{
            position: relative;
            display: grid;
            grid-template-columns: 2fr 1fr;
            min-height: rem(600);
        }
        &__main,
        &__next{
            display: flex;
            flex-flow: column;
            justify-content: flex-end;
            align-items: flex-start;
            padding: rem(50);
        }
        &__main{
            background: rgba(0,0,0,.3);
            &__title{
                @extend %big-title;
            }
            &__event{
                display: flex;
                align-items: center;
                margin: rem(30) 0;
                font-size: rem(22);
            }
            &__cta{
                margin-top: rem(20);
            }
        }
        &__next{
            background: rgba(0,0,0,.5);
            &__event{
                display: flex;
                align-items: center;
                margin-bottom: rem(10);
            }
        }
    }
}

@include responsiveTo('lg'){
    .c-conference-panel{
        .ev-time{
            margin: rem(10) 0;
        }
        .conf-panel{
            &__main{
                &__event{
                    flex-flow: column;
                    align-items: flex-start;
                }
            }
            &__next{
                &__event{
                    flex-flow: column;
                    align-items: flex-start;
                }
            }
        }
    }
}

@include responsiveTo('md'){
    .c-conference-panel{
        .conf-panel{
            &__wrapper{
                grid-template-columns: 1fr;
            }
        }
    }
}
