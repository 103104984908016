.c-conference-video{
    --grid-col-template: 2fr 1fr;
    --size-suggest-title: #{rem(18)};
    --size-suggest-descr: #{rem(16)};
    --suggest-pad: #{rem(20)} #{rem(30)};
    .conference-video{
        &__wrapper{
            width: 90%;
            margin:  0 auto;
            display: grid;
            grid-template-columns: var(--grid-col-template);
            gap: rem(40);
        }
        &__details{
            .details{
                &__title{
                    @extend %title;
                    color: getColor('secondary');
                }
                &__subtitle{
                    @extend %subtitle;
                }
                &__table{
                    margin-top: rem(60);
                    border: 1px solid getColor('primary');
                    border-radius: .5rem;
                    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
                    background: rgba(34, 46, 61, 0.3);
                    &__item{
                        padding: var(--suggest-pad);
                        display: grid;
                        grid-template-columns: rem(60) 1fr;
                        gap: rem(20);
                        align-items: center;
                        border-bottom: 1px solid getColor('primary');
                        &:last-child{
                            border-bottom: none;
                        }
                        .item{
                            &__icon{
                                justify-self: center;
                            }
                            &__title{
                                color: getColor('secondary');
                                font-size: var(--size-suggest-title);
                                text-transform: uppercase;
                                margin-bottom: rem(10);
                            }
                            &__description{
                                font-size: var(--size-suggest-descr);
                            }
                        }
                    }
                }
            }
        }
    }
}

@include responsiveTo('xl'){
    .c-conference-video{
        --size-suggest-title: #{rem(16)};
        --size-suggest-descr: #{rem(14)};
        --suggest-pad: #{rem(15)};
    }
}

@include responsiveTo('lg'){
    .c-conference-video{
        --grid-col-template: 1fr;
    }
}
