.c-zoomable {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-i-modal;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;

  &__item {
    position: relative;
    width: auto;
    height: 100%;
    display: inline-block;
    box-shadow: $sectionsShadow;
  }

  &__close {
    cursor: pointer;
    span {
      padding-right: 1rem;
    }
  }
}

@include responsiveFrom('md') {

  .c-zoomable__close {
    position: fixed;
    top: 2rem;
    left: 50%;
    font-size: 0.8rem;
    z-index: 10;
    transform: translate(-50%, 0);
    text-shadow: 1px 1px 2px black;
  }

}

@include responsiveTo('md') {
  .c-zoomable__item {
    display: flex;
    height: auto;
  }
  .c-zoomable__close {
    position: fixed;
    top: 1rem;
    left: $mainContainerPadX;
    font-size: 0.8rem;
  }
}
