.l-exhibition {
  --sensor-width: 150;
  --sensor-height: 100;

  position: relative;
  z-index: $z-i-exhib-main;

  .c-force-orientation{
    display: none;
  }

    &__close{
        position: fixed;
        top: rem(15);
        right: rem(20);
        border: 1px solid;
        padding: 0.5rem;
        line-height: 0;
        border-radius: 50%;
        color: white;
        z-index: $z-i-exhib-close;
        a{
            color: currentColor;
        }
        svg {
          width: 1rem;
          height: 1rem;
        }
    }
    &__rows-list{
        z-index: $z-i-exhib-map;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: calc(var(--sensor-width)/2 * 1px);
        display:flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
        &__item{
            color: getColor('lightgray');
            margin-bottom: rem(10);
            cursor: pointer;
            font-size: rem(14);
            text-transform: uppercase;
            transition: margin .2s ease-in-out;
            &.current{
                color: getColor('secondary');
                margin-bottom: rem(20);
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
  &__nav-sensor {
    position: absolute;
    z-index: $z-i-exhib-sensors;
    cursor: none;
    &--top {
      top: 0;
      left: calc(1px * var(--sensor-width));
      width: calc(100% - 2px * var(--sensor-width));
      height: calc(1px * var(--sensor-height));
    }

    &--bottom {
      bottom: 0;
      left: calc(1px * var(--sensor-width));
      width: calc(100% - 2px * var(--sensor-width));
      height: calc(1px * var(--sensor-height));
    }

    &--left {
      top: 0;
      left: 0;
      height: 100%;
      width: calc(1px * var(--sensor-width));
    }

    &--right {
      top: 0;
      right: 0;
      height: 100%;
      width: calc(1px * var(--sensor-width));
    }
  }

  &__cursor {
    --cursor-size: 50px;
    position: absolute;
    width: var(--cursor-size);
    height: var(--cursor-size);
    transform: translate(-50%,-50%);
    z-index: $z-i-main;
    cursor: none;
    pointer-events: none;
    border-radius: 50%;
      border:3px solid white;
      background: rgba(0,0,0,.5);
      display:flex;
      align-items: center;
      justify-content: center;
      .wip-icon{
          display: flex;
          justify-content: center;
          align-items: center;
          transform-origin: center;
          transition: transform .3s ease-in-out;
      }
    &--up {
      //background: red;
        .wip-icon{
            transform: rotate(180deg);
        }
    }

    &--down {
      //background: blue;
      //  icon down by default
    }

    &--left {
      //background: green;
        .wip-icon{
            transform: rotate(90deg);
        }
    }

    &--right {
      //background: yellow;
        .wip-icon{
            transform: rotate(-90deg);
        }
    }

    &--zoom {
      //background: fuchsia;
    }
  }

  &__mask {
    position: relative;
    height: calc(100vh - var(--h) * 1px);
    overflow: hidden;
      opacity: 1;
      transition: opacity .4s ease-in-out;
  }

  &__map-wrapper {
    position: fixed;
      top: 0;
      left: 0;
    z-index: $z-i-modal;
    width: 100%;
    height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      .c-exhibition-map{
          width: min(1200px,95%);
      }
  }

  &__menu {
    position: absolute;
    z-index: $z-i-exhib-close;
    top: rem(20);
    left: 5vw;
      opacity: 1;
      transition: opacity .3s ease-in-out;
      width: min(#{rem(400)},100vw);
      display: grid;
      grid-template-columns: 1fr auto;
      &.hidden{
          opacity: 0;
          pointer-events: none;
      }
      //select override
      .c-select{
          .select__is-required{
              display:none;
          }
          &:before{
              right: rem(15);
          }
      }
      .map-cta{
          color: getColor('text');
          align-self: center;
          padding-left: 2rem;
          span {
            position: relative;
            font-weight: $font-bold;
            display: inline-block;
            padding: 0.9em 0.2em;
            cursor: pointer;
            &:after {
              position: absolute;
              width: 100%;
              left: 0;
              bottom: 0em;
              border-bottom: 2px solid getColor('text');
              content: '';
            }
        }
      }
  }

  &__grid {
      transition: transform .4s ease-in-out;
    &__row {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      align-content: center;

      &__cell {
        height: calc(100vh - 1px * var(--h) - 1px * var(--sensor-height));
        min-width: calc(100vw - 2px * var(--sensor-width));
      }
    }
  }

  //when map is open
  &.map-on{
      .l-exhibition{
          &__mask{
              opacity: 0;
          }
      }
  }
}

@include responsiveTo('xl'){
    .l-exhibition{
        --sensor-width:100;
        --sensor-height:100;
        &__rows-list{
            display: none;
        }
        &__menu{
            &.hidden{
                //show however
                opacity: 1 !important;
                pointer-events: all !important;
            }
        }
    }
}

@include responsiveTo('lg'){
    .l-exhibition{
        --sensor-width: 20;
        --sensor-height:70;

      // in mobile map is noto using sensors to move
      .l-exhibition__nav-sensor {
        pointer-events: none;
      }
      .l-exhibition__grid__row__cell {
        height: calc(100vh - 1px * var(--h) - (1px * var(--sensor-height) * 1.5) );
        padding-bottom: 1rem;
      }

      &__menu{
          position: fixed;
          top: 0.6rem;
          left: 0;
          border-radius: 0;
            grid-template-columns: 1fr 1fr;
            .c-select{
               height: rem(45);
              margin: 0;
              border-radius: 0;
              border-top-right-radius: 1rem;
              border-bottom-right-radius: 1rem;
            }
        }
    }
}

// mobile portrait
$currBreak: map_get($viewports, 'md');
$maxWidth: map-get($map: $currBreak, $key: 'base');
@media screen and (max-width: $maxWidth * 1px) and (orientation: landscape) {
  .l-exhibition {
    padding: $mainContainerPadX;
    > * {
      &:not(.c-force-orientation) {
        display: none;
      }
    }
    .c-force-orientation {
      display: block;
    }
  }
}
