.c-exhibition-slide {
    display:flex;
    align-items: center;
    justify-content: center;
    padding: rem(50);
    .slide-content{
        width: 100%;
        height: 100%;
        position: relative;
        &__school{
            color: getColor('lightgray');
            font-size: rem(60);
            font-weight: $font-thin;
            text-transform: uppercase;
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            padding-bottom: rem(20);
            white-space: nowrap;
        }
    }

    &--intro {
        --max-lines-abstract: 5;
        width: 70%;
      .slide-content{
          display:grid;
          grid-template-columns: repeat(2,1fr);
          gap: rem(30);
          min-width: 0;
          width: 80%;
          height: 100%;
          align-items: center;
          &__image{
              height: 100%;
              overflow: hidden;
              text-align: right;
              img{
                  width: auto;
                  height: 100%;
                  object-fit: contain;
              }
          }
          &__body{
              height: 100%;
              display:flex;
              flex-flow: column;
              justify-content: center;
              &__pretitle {
                  @extend %pretitle;
              }
              &__title{
                  font-family: $font-family-secondary;
                  font-size: rem(38);
                  padding:rem(10) rem(70) rem(20) 0;
                  line-height: 1.2;
                  color: getColor('secondary');
              }
              &__description{
                  @extend %description;
                  //--l-h-px: 20px;
                  //max-height: calc(var(--l-h-px) * var(--max-lines-abstract));
                  font-size: rem(16);
                  line-height: 1.5;
                  overflow:hidden;
                  padding: 0;
                  font-weight: $font-semi-bold;
                  position: relative;

                  display: -webkit-box;
                  -webkit-line-clamp: var(--max-lines-abstract);
                  -webkit-box-orient: vertical;
                  margin: 0;
              }
              &__cta{
                  margin-top: rem(35);
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  gap: rem(20);
                  width: min(#{rem(300)}, 100%);
              }
          }
      }
    }
    &--carousel{
        .slide-content{
            //display: grid;
            //grid-template-columns: 1fr;
            width: 55%;
            .c-exhibition-carousel{

            }
            &__opera-label{
                pointer-events: none;
                position: absolute;
                top: 50%;
                right: 100%;
                font-size: rem(40);
                font-weight: $font-thin;
                color: getColor('lightgray');
                transform: translate(-50%,-50%) rotate(-90deg);
            }
            &__opera-details{
                width: 50%;
                position: absolute;
                bottom:0;
                left: calc(100% + #{rem(20)});
                padding-bottom: rem(40);
                .opera{
                    &__author{
                        font-weight: $font-semi-bold;
                        margin-bottom: rem(5);
                    }
                    &__title{
                        font-style: italic;
                        color: getColor('secondary');
                        margin-bottom: rem(15);
                    }
                    &__description,
                    &__size{
                        font-size: rem(14);
                        color: getColor('lightgray');
                        margin-bottom: rem(7);
                    }
                }
            }
        }
    }
  &--360 {
      .slide-content{
          width: min(500px,80%);
          height: 80%;
          &__image{
              width: 100%;
              height: 100%;
              position:relative;
              cursor: pointer;
              picture,img{
                  display:block;
                  width: 100%;
                  height: 100%;
                  position: relative;
                  img{
                      object-fit: cover;
                  }
                  &:after{
                      content: '';
                      display: block;
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      background: rgba(0,0,0,.5);
                  }
              }
              &__label{
                  pointer-events: none;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  display:flex;
                  flex-flow: column;
                  align-items: center;
                  justify-content: center;
                  font-size: rem(40);
              }
          }
      }
  }

  &--video {
  }

  &--360 {
  }

  &__image-layout {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: stretch;
  }

  &__col {
    flex: 1;
  }

}

.debug{
    //debug
    .c-exhibition-slide{
        border: 1px solid green;
        .slide-content{
            //debug
            border: 1px solid red;
        }
    }
}

@include responsiveTo('xxxl'){
    .c-exhibition-slide{
        padding-top: 0;
        .slide-content{
            &__school{
                display:none;
            }
        }
        &--intro{
            .slide-content{
                &__body{
                    &__description{

                    }
                }
            }
        }
    }
}

@include responsiveTo('xxl'){
    .c-exhibition-slide{
        &--intro{
            .slide-content{
                &__body{
                    &__title{
                        font-size: rem(36);
                    }
                }
            }
        }
    }
}

@include responsiveTo('xl'){
    .c-exhibition-slide{
        .slide-content{
            width: 95%;
        }
        &--intro{
            .slide-content{
                &__body{
                    height: 90%;
                    &__title{
                        padding-right: 0;
                        font-size: rem(28);
                    }
                }
            }
        }
        &--carousel{
            .slide-content{
                &__opera-label{
                    display:none;
                    //top: rem(10);
                    //right: 50%;
                    //transform: translateX(50%);
                }
                &__opera-details{
                    left: 0;
                    pointer-events: none;
                    width: 100%;
                    text-align: center;
                    //border: 1px solid getColor('primary');
                    border-radius: 3px;
                    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.5);
                    background: rgba(0, 5, 5, 0.6);
                    padding: rem(20);
                }
            }
        }
    }
}
@include responsiveTo('lg'){
    .c-exhibition-slide{
        &--intro{
            .slide-content{
                &__body{
                    &__description{
                        font-size: rem(14);
                    }
                    &__cta{
                        grid-template-columns: 1fr;
                    }
                }
            }
        }
    }
}

@include responsiveTo('md'){
    .c-exhibition-slide{
        padding: 0 0 calc(var(--sensor-height) *1px);
        &--intro{
            .slide-content{
                grid-template-columns: 1fr;
                &__image,
                &__body{
                    grid-column: 1/-1;
                    grid-row: 1/-1;
                }
                &__image{
                    position: relative;
                    picture,img{
                        display:block;
                        width: 100%;
                        height: 100%;
                        img{
                            object-fit: cover;
                        }
                    }
                    &:after{
                        position: absolute;
                        top: 0;
                        left: 0;
                        content:'';
                        width: 100%;
                        height: 100%;
                        background: rgba(0,0,0,.5);
                    }
                }
                &__body{
                    position: relative;
                    padding: 0 rem(20);
                }
            }
        }
        &--carousel{
            .slide-content{
                &__opera-label{
                    display: none;
                }
            }
        }
    }
}
