.c-inputdate{
    @extend %c-field;
    input{
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;
        padding: 0;
        color: getColor('text');
        &:-internal-autofill-selected {
            background-color: transparent;
        }
        &::-webkit-calendar-picker-indicator {
            filter: invert(1);
        }
    }
    .inputdate {
        &__label{
            top:0;
            transform: translate(0);
            font-size: rem(11);
        }
    }
}
