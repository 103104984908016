
.c-create-account-stepper {
  margin-top: rem(115);
  margin-bottom: rem(125);

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  h1 {
    font-family: $font-family-secondary;
    text-align: center;
    @extend %big-title;
    line-height: 1em;
  }

  &__content {
    display: flex;
    justify-content: center;
  }

  &__step {
    $steppadding: rem(12.5);
    padding: $steppadding $steppadding*2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: getColor('lightgray');

    &__number {
      font-family: $font-family-secondary;
      font-size: rem(36);
      font-weight: 700;
      margin-right: $steppadding;
    }

    &__text {
      position: relative;
      font-size: rem(18);
    }

    &--highlighted {
      border: rem(2) solid getColor('secondary');
      border-radius: rem(30);

      .c-create-account-stepper__step__number {
        color: getColor('secondary');
      }
      .c-create-account-stepper__step__text {
        color: getColor('text');
      }
    }
  }
}


@include responsiveTo(lg) {
  .c-create-account-stepper {
    &__step {
      .c-create-account-stepper__step__text {
        display: none;
      }
      &--highlighted {
        .c-create-account-stepper__step__text {
          display: block;
        }
      }
    }
  }
}