/**
Input radio as switch on/off style. You need the next structure:
<div class="c-switch">
  <label>
    <input type='checkbox'>
    <span class='slider'></span>
  </label>
</div>

**/

.c-switch{
  position: relative;
  width: 60px;
  height: 30px;
  &.success{
    .slider{
      &:after{
        opacity:1;
        transform: translate(0,-60%) rotate(45deg) scale(1);
      }
    }

  }
  input{
    opacity: 0;
    width: 0;
    height: 0;
    &:checked{
      &+.slider{
        background-color: lawngreen;
        &:before{
          transform:translate(100%,-50%);
          transform-origin: right center;
        }
      }
    }
  }
  .slider{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    border-radius: 20px;
    transition: .4s;
    background:lightgrey;
    pointer-events: none;
    overflow: hidden;
    &:before{
      content:'';
      position: absolute;
      cursor: pointer;
      top: 50%;
      left: 2px;
      right: 0;
      bottom: 0;
      background-color: white;
      transition: .4s;
      border-radius: 50%;
      height: 90%;
      width: calc(50% - 2px);
      transform: translateY(-50%);
      pointer-events: all;
    }
    &:after{
      pointer-events: none;
      opacity:0;
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 15px;
      width: 9px;
      height: 15px;
      border-bottom: 2px solid;
      border-right: 2px solid;
      border-color: lawngreen;
      transform: translate(30px, -60%) scale(0);
      transition:all .7s;
    }
  }
}

