.c-lang-switcher{
    --lang-border-color: transparent;
    .switcher{
        &__btn {
            padding: 0 rem(15);
            cursor: pointer;
            position: relative;
            &--lang {
                text-transform: uppercase;
                border-left: 1px solid var(--lang-border-color);
                .c-nav-service__caret {
                    padding-left: 0.5rem;
                }
                svg {
                    width: 0.5rem;
                }
            }
        }
        &__dropdown {
            position: absolute;
            z-index: $z-i-menu;
            top: calc(100% + 1em);
            left: 50%;
            transform: translateX(-50%);
            background: white;
            color: black;
            box-shadow: 0 0 5px 0 rgba(0,0,0,0.75);
            &:before {
                position: absolute;
                box-shadow: -2px 0px 0px 0px rgba(0, 0, 0, 0.75);
                top:-0.25em;
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
                content:'';
                background: white;
                width: 0.5em;
                height: 0.5em;
            }
            &__cta {
                display: block;
                cursor: pointer;
                padding: 1em 1.5em;
                &:not(:last-child) {
                    border-bottom: 1px solid getColor('lightgray');
                }
            }
        }
        &__dropdown {
            text-align: center;
            border-radius: 3px;
        }
    }
}
