
/* FONT WEIGHTS*/
$font-bold: 700;
$font-semi-bold: 600;
$font-regular: 400;
$font-light: 300;
$font-thin: 100;

/* FONT FACES DECLARATIONS */
//Thin plus Italic
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: $font-thin;
    src: url('../fonts/Montserrat-Thin.ttf') format('truetype');
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: $font-thin;
    src: url('../fonts/Montserrat-ThinItalic.ttf') format('truetype');
}
//Light plus Italic
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: $font-light;
  src: url('../fonts/Montserrat-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: $font-light;
    src: url('../fonts/Montserrat-LightItalic.ttf') format('truetype');
}
//Regular
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: $font-regular;
  src: url('../fonts/Montserrat-Regular.ttf') format('truetype');
}
//Italic
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: $font-regular;
    src: url('../fonts/Montserrat-Italic.ttf') format('truetype');
}
//Semibold plus italic
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: $font-semi-bold;
  src:  url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: $font-semi-bold;
    src:  url('../fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
}
//Bold plus Italic
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: $font-bold;
  src:  url('../fonts/Montserrat-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: $font-bold;
    src:  url('../fonts/Montserrat-BoldItalic.ttf') format('truetype');
}
//YESEVA
@font-face {
  font-family: 'YesevaOne';
  font-style: normal;
  font-weight: $font-regular;
  src: url('../fonts/YesevaOne-Regular.ttf') format('truetype');
}

$font-family-base: 'Montserrat',sans-serif;
$font-family-secondary: 'YesevaOne',sans-serif;



html {
  font-family: $font-family-base;
  font-size: 16px;
}


a {
  color: getColor('tertiary');
  &:hover {
    color: getColor('secondary');
  }
}


/* -------- CARD DATA-MODEL STANDARDS -------- */

%pretitle {
  font-size: 0.9rem;
  text-transform: uppercase;
  line-height: 1em;
  padding: 0.2rem 0;
  font-family: $font-family-base;
}
%big-title{
  font-size: 3rem;
  line-height: 1.4em;
  padding: 0.4rem 0;
  font-family: $font-family-secondary;
}
%title {
  font-size: 1.9rem;
  line-height: 1em;
  padding: 0.4rem 0;
  font-family: $font-family-secondary;
    @include responsiveTo('md'){
        font-size: 1.8rem;
    }
}

%subtitle {
  font-size: 1.1rem;
  line-height: 1em;
  padding: 0.2rem 0;
  font-family: $font-family-base;
}

%description {
  font-size: 1rem;
  line-height: 1.4rem;
  padding: 1rem 0;
    letter-spacing: 1px;
}

.tag-title {
  display:inline-block;
  background: getColor('light');
  color: getColor('text-dark');
  font-weight: bold;
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
}


/* -------- TYPE UTILITIES -------- */

.note {
  font-size: 0.8rem;
}

%resetUl {
  list-style: none;
  margin:0;
  padding: 0;
}


/* -------- TYPE UTILITIES -------- */

/*
use it adding in your html a:
  data-section-label="your label"
and extending your scss with
  @extend %with-vertical-label;
*/


%with-vertical-label {
  &:before {
    content: attr(data-section-label);
    color: getColor('secondary');
    font-size: rem(20);
    font-weight: $font-semi-bold;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    position: absolute;
    top: 0;
    right: calc(100% + #{rem(25)});
  }
}


//WIP ICONS

.wip-icon{
    vertical-align: middle;
}

@include responsiveTo('md'){
    %big-title{
        font-size: 2rem;
        line-height: 1.2;
    }
    %pretitle{
        font-size: .8rem;
    }
    %title{
        font-size: 1.8rem;
    }
    %description{
        font-size: 0.85rem;
    }
}
