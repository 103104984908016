%c-field{
    // todo: avoid nested input names here
    position: relative;
    height: rem(60);
    //min-width: rem(400);
    color: getColor('text');
    background: getColor('darkgray');
    border-radius: rem(30);
    display: flex;
    align-items: center;
    padding: rem(7) rem(20);
    margin:0 0 1rem 0;
    &.filled{
        .input,
        .select ,
        .textarea,
        .options-group,
        .inputfile,
        .inputdate{
            &__label{
                top:0;
                transform: translate(0);
                font-size: 0.7em;
            }
        }
    }
    &.read-only{
        background: transparent;
        border: 2px solid getColor('lightgray');
    }
    &.is-required{
        .input,
        .select,
        .textarea,
        .options-group ,
        .inputfile,
        .inputdate{
            &__is-required{
                opacity:1;
            }
        }
    }
    &.has-error{
        color: getColor('warning');
    }

    .input,
    .select,
    .textarea,
    .options-group,
    .inputfile,
    .inputdate {
        &__wrapper{
            flex: 1;
            position: relative;
            height: 100%;
        }
        &__is-required{
            flex: 0 0 1.2rem;
            @extend .required-sign;
        }
        &__label{
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0,-50%);
            transition: all .3s ease-in-out;
        }
        &__error-msg{
            position: absolute;
            top: calc(100% - 0.5em);
            left: 0;
            color: getColor('text');
            font-size: 0.75rem;
            background: getColor('error');
            padding: 0.25em 0.5em;
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
            z-index: 1;
            &:before {
                position: absolute;
                top: -0.25rem;
                right: 1rem;
                width: 0.5rem;
                height: 0.5rem;
                content: "";
                transform: rotate(-135deg);
                background: getColor('error');
                box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.75);
                z-index: 1;
            }
        }
        &__icon{
            flex: 0 0 5%;
            //position: absolute;
            //top: 50%;
            //left: 100%;
            //transform: translate(0,-50%);
        }
    }
}
