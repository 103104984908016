.c-nav-service {
  a {
    color: currentColor;
    text-decoration: none;
  }

  &__item {
    font-size: rem(14);
    color: getColor('lightgray');
    font-weight: $font-semi-bold;
    letter-spacing:2px;
    &:last-child {
      color: getColor('text');
      padding-right: 0;
    }
  }

  &__btn {
    padding: 0 rem(15);
    cursor: pointer;
    position: relative;
    svg {
      width: 1.4rem;
    }
  }

  &__btngroup {
    position: relative;
    display: flex;
    align-items: center;
      .c-lang-switcher{
          --lang-border-color:#{getColor('light')};
      }
  }

  &__dropdown {
    position: absolute;
    z-index: $z-i-menu;
    top: calc(100% + 1em);
    left: 50%;
    transform: translateX(-50%);
    background: white;
    color: black;
    box-shadow: $sectionsShadow;
    border-radius: 3px;
    text-align: center;
    &:before {
      position: absolute;
      box-shadow: -2px 0px 0px 0px rgba(0, 0, 0, 0.75);
      top:-0.25em;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      content:'';
      background: white;
      width: 0.5em;
      height: 0.5em;
    }

    &__cta {
      display: block;
      cursor: pointer;
      padding: 1em 1.5em;
      &:not(:last-child) {
        border-bottom: 1px solid getColor('lightgray');
      }
    }
  }
}

@include responsiveTo(xl) {
  .c-nav-service {
    $itemPadding: 0.6rem;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    &__item {
      padding: $itemPadding 0 ;
      &--icons {
        position: fixed;
        top: $mainContainerPadY;
        left: $mainContainerPadY;
      }
    }
  }

}


@include responsiveFrom(xl) {
  .c-nav-service {
    $itemPadding: 1rem;
    padding: rem(21) rem(30);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &__item{
      padding: 0 $itemPadding;
    }
  }
}

@include responsiveFrom(xxl) {
  .c-nav-service {
    $itemPadding: 1.8rem;
    &__item{
      padding: 0 $itemPadding;
    }
  }
}
