
.l-profile-form {
  @include mainContainer();
}

.l-profile-form-col {
  &--photo {
    padding-bottom:2rem;
    img {
      width: 100%;
    }
  }
}


@include responsiveFrom(lg) {
  .l-profile-form-row {
    display:flex;
    justify-content: center;
    padding-bottom: rem(60);
  }

  .l-profile-form-col {
    padding: 0 rem(80);
    flex: 0 0 1;
    width: 40%;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    &--photo {
      width:  20%;
      padding-right: 0;
      padding-bottom: 2rem;
      img {
        padding-bottom: 2rem;
      }
    }
    &--shipping {
      border-left: 2px solid getColor('darkgray');
    }
  }

}

@include responsiveTo(lg) {
  .l-profile-form-row {
    display: block;
  }
  .l-profile-form-col {
    padding:0;
    width: auto;
  }
  .l-profile-form-col--photo {
    width: auto;
  }
}