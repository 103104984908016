a{
    text-decoration: none;
}

%btn{
    position: relative;
    --border-radius: 20px;
    --btn-border-color: transparent;
    --btn-text-color: currentColor;
    text-transform: uppercase;
    padding: rem(10) rem(15);
    font-size: rem(14);
    font-weight: $font-semi-bold;
    //letter-spacing: 1px;
    //display: flex;
    align-items: center;
    justify-content: center;
    min-width: rem(150);
    min-height: rem(40);
    text-align: center;
    cursor: pointer;
    user-select: none;
    line-height: 1;
    border-radius: var(--border-radius);
    transition: all 0.2s ease-in-out;
    background-color: transparent;
    color: var(--btn-text-color);
    border: 1px solid transparent;
    outline: none;
    box-shadow: 0 5px 10px -8px rgba(0, 0, 0, 0.8);
    &:after {
        position: absolute;
        transition: all 0.25s ease-out;
        top: 50%; left: 50%; transform: translate(-50%, -50%);
        width: 0; height: calc(100% - 0.3em);
        border-radius: var(--border-radius) ;
        content: '';

    }
    &:hover{
        color: var(--btn-text-color);
        transform: scale(1.02);
        &:after {
            width: calc(100% - 0.3em);
            background: linear-gradient(180deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.2) 40%, rgba(255,255,255,0) 100%);
        }
    }
    &.medium{
        font-size: rem(18);
        min-width: rem(180);
    }
    &.large{
        font-size: rem(20);
        min-height: rem(50);
        min-width: rem(200);
    }
    &.small{
        font-size: rem(12);
        min-width: rem(120);
    }
    &.wide {
        display: flex;
        width: 100%;
    }
    &.stroked{
        background-color: transparent;
        border-color: var(--btn-border-color);
        color: var(--btn-border-color);
        &:hover{
            filter:none;
            color: var(--btn-text-color);
            background-color: var(--btn-border-color);
        }
    }
}
.btn-primary{
    @extend %btn;
    --btn-border-color: #{getColor('primary')};
    --btn-text-color: #{getColor('text')};
    background-color: getColor('primary');
}
.btn-secondary{
    @extend %btn;
    --btn-border-color: #{getColor('secondary')};
    --btn-text-color: #{getColor('text-dark')};
    background-color: getColor('secondary');
}
.btn-light{
    @extend %btn;
    --btn-border-color: #{getColor('light')};
    --btn-text-color: #{getColor('text-dark')};
    background-color: getColor('light');
}
.btn-dark{
    @extend %btn;
    --btn-border-color: #{getColor('dark')};
    --btn-text-color: #{getColor('light')};
    background-color: getColor('dark');
}


.btn-red{
    @extend %btn;
    --btn-border-color: #{getColor('darkred')};
    --btn-text-color: #{getColor('text')};
    background-color: getColor('darkred');
}


.btn-link {
    @extend %btn;
    background: transparent;
    border: 0px;
    color: getColor('primary');
    &:after {display: none}
    box-shadow: none;
}


.btn-withicon {
    > span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .wip-icon {
            margin-right:1em;
        }
    }
}

.arrow-link {
    color: currentColor;
    display: flex;
    align-items: center;
    text-decoration: none;

    .icon-arrow {
        margin-right: rem(20);
        display: block;
        line-height: 1;
        width: rem(25);
    }
}

