.menu {
    $w : 1.6rem;
    $h : 1.2rem;
    $lineW : 0.2rem;
    position:relative;
    width: $w;
    height: $h;
    &__icon {
        display: block;
        position: absolute;
        top: 0;
        left:0;
        height: $w;
        width: $w;
        cursor: pointer;
        text-indent: -9999px; //Hide the label
        //Remove blinking cursor
        border: none;
        color: transparent;
        text-align: center;
        &:focus {
            outline: none;
        }
    }

    &__icon__row {
        position: absolute;
        display: block;
        background: white;
        width: $w;
        height: $lineW;
        border-radius: $lineW;
        left: 0;
        text-indent: 0;
        transition: all .6s cubic-bezier(.5, .1, 0, 1.2);

        &:first-child {
            top: 0;
        }

        &:nth-child(2) {
            top: ($h / 2) - ($lineW/2) ;
        }

        &:nth-child(3) {
            top: $h - $lineW ;
        }
    }

    input[type=checkbox] {
        display: none;
        visibility: hidden;
        &:checked {
            ~ label .menu__icon__row:first-child {
                top: 0px;
                width: $w;
                transform: translateX(-8px) rotate(-45deg) translateY(12px);
            }

            ~ label .menu__icon__row:nth-child(2) {
                opacity: 0;
            }

            ~ label .menu__icon__row:nth-child(3) {
                top: 14px;
                width:$w;
                transform: translateX(-5px) rotate(45deg) translateY(-8px);
            }
        }
    }
}

  