.c-chat-wee-mobile-closer {
    display: none;
}

@include responsiveTo('md') {
    .c-chat-wee-mobile-closer {
        z-index: 99999991;
        display: flex;
        width: 3rem;
        height: 3rem;
        position: fixed;
        top: 0;
        right: 0;
        justify-content: center;
        align-items: center;
        animation: 1s appear 1s;
        animation-fill-mode: forwards;
        opacity: 0;
    }
}
// a css setTimeOut to wait the third party chat to appear
// no callback api
@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}