.l-profile {
  @include mainContainer();

  //.c-input.read-only {
  //  padding: 0;
  //  border: 0;
  //  font-size: 1.2rem;
  //  .input__is-required {
  //    display: none;
  //  }
  //}
    .dataview{
        font-size: 1.2rem;
    }
    &__view-doc{
        display: inline-block;
        margin-bottom: rem(20);
        padding: 0 rem(20);
    }

  &__help {
    margin-top: $siteComponentsMargin;
    .profile__help__content__title {
      @extend %subtitle;
      font-size: 1.2rem;
    }
    .profile__help__content__text {
      @extend %description;
      padding-bottom: 2rem;

    }
    .btn-primary {
      color: getColor("light");
      border-color: getColor('light');
      border-radius: 0;
    }
  }

}

@include responsiveFrom(lg) {
  .l-profile {
    &__row {
      //display: flex;
        display:grid;
        grid-template-columns: repeat(4,1fr);
      &__col {
        &--photo {
          //width: 25%;
            width: 100%;
          padding-right: 2rem;
          img {
            width: 100%;
          }
        }
        &--data {
          flex-grow: 1;
          padding: 0 1rem;
        }
        &--editrequest,
        &--sidetools {
          padding-left: 1rem;
          //width: 20%;
            width: 100%;
          [class*="btn"] + [class*="btn"] {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}


@include responsiveFrom(xl) {
  .l-profile {
    &__row {
      padding: 0 4rem;
      &__col {
        &--photo {
          //width: 30%;
          padding-right: 4rem;
        }
        &--editrequest {
          padding-left: 2rem;
          //width: 25%;
        }
      }
    }
  }
}

@include responsiveTo(lg) {
  .l-profile {
    &__row {
      &__col {
        &--photo {
          padding-bottom: 3rem
        }
      }
    }
  }
}
