.l-sidebar{
    //position: fixed;
    //top:0;
    //left: 0;
    //width: 100%;
    //height: 100%;
    //pointer-events: none;
    //display:flex;
    //justify-content: flex-end;
    .sidebar{
        &__program{
            position: fixed;
            top: 0;
            right: 0;
            animation-name: fromRight;
            animation-duration: 0.5s;
            animation-fill-mode: backwards;
            pointer-events: all;
            color: getColor('dark');
            flex: 1;
            height: 100%;
            max-width: rem(900);
            width: 100%;
            z-index: $z-i-sidebar;
            overflow-y: auto;
            padding-left: rem(20);
        }
    }
    &.programs-on{

    }
}

@include responsiveTo('md'){
    .l-sidebar{
        .sidebar{
            &__program{
                padding: 0;
            }
        }
    }
}


@keyframes fromRight {
    0%   {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
