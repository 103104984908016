.l-password-reset-header {
  @include mainContainer();
  text-align:center;
  @extend %pretitle;
  text-align:center;
}

.l-password-reset {
  max-width: rem(500);
  margin: 2rem auto;

  &__info {
    margin: 2rem 0;
    &__text {}
    &__email {
      font-size: 1.3em;
    }
  }
  &__submit {
    margin-top: rem(40);
  }
}