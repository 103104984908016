.backtotop {
    position: fixed;
    right: $mainContainerPadX;
    bottom: $mainContainerPadY;
    color: white;
    z-index: $z-i-mainup;
    width: 1rem;
    height: 1rem;
    padding: 1.3rem;
    border: 1px solid getColor('text');
    border-radius: $border-radius;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0rem 2rem 0rem rgb(0,0,0);
    cursor: pointer;
    display: flex;
    transition: all 1s ease;
    opacity: 0;
    transform: translateX(2rem);
    pointer-events: none;
    &--visible {
        opacity: 1;
        transform: translateX(0);
        pointer-events: auto;
    }
}

