
.c-concert {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;
  width: 100%;
  .c-force-orientation {
    display: none;
  }
  &:hover {
    .c-concert__cameras {
      opacity: 1;
    }
  }
  &__skip {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background: transparent;
    border: 0;
    z-index: $z-i-mainup;
    &:hover {
      transform: translateX(-50%);
    }

  }
  &__intro {
    @extend %absoluteCentered;
    z-index: $z-i-mainup;
    text-align: center;
    &__title {
      @extend %big-title;
    }
    &__description {
      @extend %description;
      padding: 1rem 0 2rem 0;
    }
  }
  .c-loader {
    @extend %absoluteCentered;
  }
  &__videos {
    position: relative;
    z-index: $z-i-main;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .c-concert__player {
      position: relative;
      width: 100%;
      padding-top: 56%; //16:9 fissi
      line-height: 0;
      video {
        @extend %absoluteCentered;
        box-shadow: $sectionsShadow;
        width: 100%;
      }
      &__poster {
       @extend %absoluteCentered;
        background: url('../images/poster_concert-hall.jpg') no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  &__tools {
    $toolSize: 3rem;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    z-index: $z-i-sidebar;
    &__cta {
      width: $toolSize;
      height: $toolSize;
      background: transparent;
      color: getColor('text');
      cursor: pointer;
      margin-top: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__cameras {
    @extend %absoluteCentered;
    transition: all 0.8s ease;
    width: 100%;
    z-index: $z-i-mainup;
    opacity: 0.3;
    &__track {
      line-height: 0;
      font-size: 0;
    }
    &__cameras__ctas {
      @extend %absoluteCentered;
    }
    &__cta {
      $size: 16px;
      position: absolute;
      width: $size;
      height: $size;
      background: white;
      border-radius: 50%;
      cursor: pointer;
      &:before {
        @extend %absoluteCentered;
        z-index: 10;
        width: $size;
        height: $size;
        content: '';
        border: 2px solid white;
        transition: all 0.25s ease;
        border-radius: 50%;
      }
      &--hover {
        background: getColor('secondary');
        &:before {
          $hoverSize: $size + 8;
          width: $hoverSize;
          height: $hoverSize;
          border: 2px solid white;
        }
        &.c-concert__cameras__cta:after {
          opacity: 1;
        }
      }
      &--hover-small {
        &:before {
          $hoverSize: $size + 4;
          width: $hoverSize;
          height: $hoverSize;
          border: 1px solid white;
        }
        &.c-concert__cameras__cta:after {
          opacity: 1;
          font-size: 0.8em;
        }
      }
      //&[class*=".c-concert__cameras__cta--hover"] {
      //  .c-concert__camera__cta:after{
      //    opacity: 1;
      //  }
      //}
      &:after {
        position: absolute;
        content: attr(data-label);
        opacity: 0;
        transition: all 0.5s ease;
        text-align: center;
        text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.8);
      }
      $labelDistance: 3*$size;
      &:nth-child(1) {
        top: calc(50% - #{$size/2});
        left: 13.2%;
        &:after {
          top: 0;
          left: $labelDistance;
        }
      }
      &:nth-child(2) {
        top: calc(0% - #{$size/2});
        left: 32%;
        &:after {
          top: $labelDistance;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &:nth-child(3) {
        top: calc(0% - #{$size/2});
        left: 50%;
        &:after {
          top: $labelDistance;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &:nth-child(4) {
        top: calc(0% - #{$size/2});
        right: 32%;
        &:after {
          top: $labelDistance;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &:nth-child(5) {
        top: calc(50% - #{$size/2});
        right: 13.2%;
        &:after {
          top: 0;
          right: $labelDistance;
        }
      }
      &:nth-child(6) {
        top: calc(100% - #{$size/2});
        right: 32%;
        &:after {
          bottom: $labelDistance;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &:nth-child(7) {
        top: calc(100% - #{$size/2});
        left: 50%;
        &:after {
          bottom: $labelDistance;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &:nth-child(8) {
        top: calc(100% - #{$size/2});
        left: 32%;
        &:after {
          bottom: $labelDistance;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

// under 1340
@include responsiveFrom('xl') {
  .c-concert__videos {
    width: 71%;
  }
}

// over 1340
@include responsiveFrom('xxl') {
  .c-concert__videos  {
    width: 90%;
  }
}

// mobile portrait
$currBreak: map_get($viewports, 'md');
$maxWidth: map-get($map: $currBreak, $key: 'base');
@media screen and (max-width: $maxWidth * 1px) and (orientation: portrait) {
  .c-concert {
    > * {
      &:not(.c-force-orientation) {
        display: none;
      }
    }
    .c-force-orientation {
      display: block;
    }
  }
}
