.l-thank-you {
    @include mainContainer();
    display: flex;
    justify-content: center;
    padding: 10% 0;

    &__col {
        &--content {
            text-align:center;
            .l-thank-you__title {
                margin-bottom: 2rem;
            }
            a[class*="btn"] {
                display: inline-flex;
                width: rem(250);
            }
        }
    }
    &__title {
        @extend %big-title;
        //font-size: rem(65);
    }

    &__circle {
        display: block;
        color: getColor('tertiary');
        svg {
           width: 100%;
            height: 100%;
            color: getColor('tertiary');
        }
    }
}


@include responsiveFrom(lg) {
    .l-thank-you {
        display: flex;

        &__col {
            &--aside {
                width: rem(150);
                margin: 0 2rem 0 0;
            }
            &--content {
                width: rem(800);
            }
        }
    }

}

@include responsiveTo(lg) {
    .l-thank-you {
        flex-direction: column;
        &__title {
            //font-size: rem(65);
        }
        &__circle {
            text-align: center;
            svg {
                position: relative;
                margin:0 auto;
                width: rem(200);
            }
        }
    }

}