.c-wip-slider{
    --slide-opacity: transparent;
    .glide{
        &__slide{
            position: relative;
            display: block;
            &:after{
                content: '';
                position: absolute;
                top:0;
                left:0;
                background: var(--slide-opacity);
                width: 100%;
                height: 100%;
                transition: background .7s ease-in-out;
            }
            &--active{
                --slide-opacity: transparent;
            }
        }
        &__bullet{
            background-color: getColor('light');
            &--active{
                background-color: getColor('secondary');
                transform: scale(1.5);
            }
        }
    }
    &[data-focus-active='true']{
        --slide-opacity: rgba(0,0,0,.7);
    }
}
