.c-delegation-form {
  position: relative;
  border: 1px solid getColor('secondary');
  padding: rem(45);
  @extend %with-vertical-label;

  &__header {
    color: getColor('secondary');
    padding-bottom: rem(50);
    em {
      text-transform: uppercase;
      font-weight: 700;
    }
  }


  &__section {
    margin-bottom: 2rem;
  }


  &__section-label {
    @extend .tag-title;
  }

}

@include responsiveFrom(lg) {
  .c-delegation-form {
    .is-mobile {
      display: none;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      color: getColor('secondary');
      padding-bottom: rem(50);
      em {
        text-transform: uppercase;
        font-weight: 700;
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;
    }
      &__submit{
          display: flex;
          align-items: center;
          justify-content: flex-end;
      }

    &__section {
      min-width: 30%;
      flex-grow: 1;
        position: relative;
      &:first-child {
        margin-right: 2rem;
          &:after{
              content: '';
              position: absolute;
              top: 0;
              right: -1rem;
              height: 100%;
              border-right: 2px solid getColor('secondary');
          }
      }
      &__row {
        display: flex;
        justify-content: space-between;
        &__col {
          width: 50%;
          &:not(:first-child) {
            padding-left:2rem
          }
        }
      }
    }
  }
}


@include responsiveTo(lg) {
  .c-delegation-form {
    .is-desktop {
      display: none;
    }
  }
}
