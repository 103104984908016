.fixednavigation {
    position:fixed;
    top:0;
    left:0;
    width: 100%;
    z-index: $z-i-menu;
    background: linear-gradient(180deg, rgba(8,28,55,0.95) 0%, rgba(8,28,55,0.85) 48%, rgba(8,28,55,0) 98%);
}

@include responsiveTo(lg) {
    body {
        --h: 95;
    }
}
@include responsiveFrom(lg) {
    body {
        --h: 110;
    }
}
@include responsiveFrom(xl) {
    body {
        --h: 160;
    }
}
@include responsiveFrom(xxl) {
    body {
        --h: 180;
    }
}

@media only screen and (min-width: 1340px ) and (max-height: 700px ) {
    body {
        --h: 130;
    }
}

body{
    //padding-top: calc((var(--h) + 20)* 1px) ;
    padding-top: calc(var(--h) * 1px);
    .fixednavigation {
        height: calc(var(--h) * 1px);
    }
    &.splashed {
        transition: none;
        .c-splash-welcome {
            margin-top: calc(var(--h) * -1 * 1px);
        }
        .l-home {
            padding-top: calc(var(--h) * 1px);
        }
    }
    &.conference-video{
        --h:0;
    }
    &.clean{
        --h:0;
        @include responsiveTo('md'){
            --h: 0;
        }
    }
}
