
.c-360-viewer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgba(0,0,0,.5);
    z-index: $z-i-modal;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    .viewer{
        &__wrapper{
            width: 90vw;
            height: min(900px,90vh);
            margin: 0 auto;
            display: flex;
            flex-flow: column;
        }
        &__close{
            align-self: flex-end;
            border: 2px solid currentColor;
            border-radius: 50%;
            padding: 5px;
            line-height: 0;
            margin-bottom: rem(10);
            cursor:pointer;
        }
    }
}
