.l-delegation {
  @include mainContainer();
  margin-top: 2*$siteComponentsMargin;

  &--completed {
    .c-section-header {
      padding-left: rem(45);
    }
  }
  &--created {
    .c-section-header {

    }
  }
}