$w : 3em;

.c-loader {
    position: relative;
    margin: 1rem auto;
    width: $w;
    height: $w;
    &--full {
        position: fixed;
        top:0;
        left: 0;
        content: '';
        background: getColor('darkprimary');
        width: 100%;
        height: 100%;
        z-index: $z-i-modal;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.c-loader:after {
    position: relative;
    content: " ";
    display: block;
    width: $w;
    height: $w;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: #fff transparent #fff transparent;
    animation: loading 0.8s linear infinite;
}
@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
