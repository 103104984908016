.c-exhibition-splash{
    min-height: calc(100vh - 1px * var(--h));
    position: relative;
    --opacity-content: 1;
    .exhibition-splash{
        &__sidenav{
            opacity:var(--opacity-content);
            position: absolute;
            top: 50%;
            left: 5vw;
            transform: translateY(-50%);
            transition: opacity .3s ease-in-out;
            &__item{
                color: getColor('lightgray');
                margin-bottom: rem(10);
                cursor: pointer;
                font-size: rem(14);
                text-transform: uppercase;
                transition: margin .2s ease-in-out;
                a{
                    color: currentColor;
                }
                &.current{
                    color: getColor('secondary');
                    margin-bottom: rem(20);
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
        &__wrapper{
            @include mainContainer();
            max-width: rem(800);
            padding-top: 5vh;
            opacity:var(--opacity-content);
            transition: opacity .3s ease-in-out;
        }
        &__pretitle{
            font-size: rem(20);
            margin-bottom: rem(10);
            color: getColor('secondary');
        }
        &__big-title{
            @extend %big-title;
            line-height: 1.2;
        }
        &__title{
            @extend %title;
            margin-bottom: rem(40);
        }
        &__abstract{
            font-size: rem(30);
            color: getColor('lightgray');
            font-weight: $font-thin;
            margin-bottom: rem(20);
        }
        &__description{
            @extend %description;
        }
        &__intro-trigger{
            color: getColor('secondary');
            text-decoration: underline;
            cursor: pointer;
            line-height: 1.5;
        }
        &__cta{
            margin-top: rem(40);
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__intro-modal{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            &__wrapper{
                @include mainContainer();
                position: relative;
            }
            &__close{
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
                padding: 5px;
                border: 2px solid currentColor;
                border-radius: 50%;
            }
            &__label{
                color: getColor('lightgray');
                font-size: rem(40);
                font-weight: $font-thin;
                text-align: center;
                text-transform: uppercase;
                margin-bottom: rem(40);
            }
            &__content{
                column-count: auto;
                column-width: max(#{rem(320)},#{rem(400)});
                border-left: 1px solid currentColor;
                border-right: 1px solid currentColor;
                padding: rem(20);
                font-size: rem(14);
                line-height: 1.4;
                letter-spacing: 1px;
                p{
                    margin:0;
                }
            }
            &__author,
            &__author-role{
                font-size: rem(14);
            }
            &__author{
                margin-top: rem(40);
            }
            &__author-role{
                color: getColor('secondary');
                font-style: italic;
            }
        }
    }

    &.intro-on{
        --opacity-content:0;
    }
}
@include responsiveTo('xl'){
    .c-exhibition-splash{
        .exhibition-splash{
            &__sidenav{
                display:none;
            }
        }
    }
}
@include responsiveTo('lg'){
    .c-exhibition-splash{
        .exhibition-splash{
            &__sidenav{
                display: none;
            }
        }
    }
}
@include responsiveTo('md'){
    .c-exhibition-splash{
        .exhibition-splash{
            &__big-title,
            &__abstract,
            &__title,
            &__description,
            &__intro-trigger{
                padding-right: rem(15);
            }
            &__abstract{
                font-size: rem(24);
            }
            &__intro-trigger{
                font-size: rem(14);
            }
        }
    }
}
