.c-notfound {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @include mainContainer();
    max-width: rem(800);
    &__title {
        @extend %big-title;
    }
    &__content {
        padding: 2rem 0;
    }
    &__action {

    }
}