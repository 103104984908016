.l-concert{
    @include mainContainer();
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

@include responsiveTo('lg'){
    .l-concert{
    }
}


@media screen and (max-width: 1023px) and (orientation: 'landscape'){
    body {
        padding-top: 0;
    }
    .l-header-navs, .l-sidebar, .c-footer {
        display:none;
    }
    .l-concert {
        width: 100vw;
        height: 100vh;
    }

    .c-concert {
        width: 100vw;
        height: 100vh;
        &__tools {
            left: 0;
            &__cta {
                background: transparent;
            }
        }
        &__videos {
            width: 100vw;
            height: 100vh;
            video {
                position: absolute;
                top:50%;
                left:50%;
                transform: translate(-50%, -50%) ;
                height: 100vh;
            }
        }
    }

}