$maxTextWidth: $siteMaxBodyText;
$ComponentverticalPadding: $siteComponentsMargin;

.wip-richtext {
  position: relative;
  padding-top: $ComponentverticalPadding;
  padding-bottom: $ComponentverticalPadding;
  @include mainContainer();
  max-width: $maxTextWidth;
  color: getColor('text');
  &__pretitle {
    @extend %pretitle;
  }
  &__title {
    @extend %title;
  }
  &__subtitle {
    @extend %subtitle;
  }
  &__text {
    line-height: 1.5;
      letter-spacing: 1px;
    p:last-child {
      margin-bottom: 0;
    }
  }

  .wip-richtext__cta {
    display: flex;
    margin-top: 1em;
    button + button,
    button + input[type="button"],
    button + a,
    input[type="button"] + button,
    input[type="button"] + input[type="button"],
    input[type="button"] + a,
    a + button,
    a + input[type="button"],
    a + a {
      margin-left: 0.5em;
    }
  }
  &.highlight{
    color: getColor('secondary');
    .wip-richtext__text{
      padding: rem(30) rem(40);
      font-size: rem(20);
      border: 5px solid currentColor;
        p{
            &:first-child{
                margin-top: 0;
            }
        }
    }
  }
}





/* --------------------------------------------------- */
/* ------- scroll animation -------------------------- */
/* --------------------------------------------------- */


/* ------- scroll animation: transitions & delays ---- */

.wip-richtext {
  > [class*="wip-richtext__"] {
    @for $i from 1 to 5 {
      &:nth-child(#{$i}) {
        transition: all 1s ease ($i) * 0.2s;
      }
    }
  }
}

/* ------- scroll animation: out properties ---------- */

.out-view {
  .wip-richtext__title {
    opacity: 0;
    transform: translateX(0.2em);
  }
  .wip-richtext__text {
    transform: translateX(-0.2em);
    opacity: 0;
  }
}
