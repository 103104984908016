.c-select{
  @extend %c-field;
  &::before {
    position: absolute;
    top: calc( 50% - 0.5em );
    right: 2rem;
    border-top: 3px solid white;
    border-right: 3px solid white;
    width: 0.5em;
    height: 0.5em;
    transform: rotate(135deg);
    content: "";
  }
  select{
    position:relative;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    color: getColor('text');
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;       /* Remove default arrow */
    //background-image: url();   /* Add custom arrow */
    option {
      background: getColor('darkblue');
    }
  }

  .select {
    &__label{
      top:0;
      transform: translate(0);
      font-size: rem(11);
    }
  }
}
