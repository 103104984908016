.c-options-group{
  @extend %c-field;
  padding: 0 0 0 rem(20); //
  background: transparent;
  border-color:transparent;
  height: auto;

  &.read-only{
    border-color:transparent;
  }

  .options-group {
    &__label{
      position: relative;
      transform: none;
      margin-bottom: 0.5rem;
    }
    &__wrapper {
      label {
        margin-right: rem(20);
        input{
          margin-right: rem(10);
        }
      }
    }
    &__error-msg {
      position: relative;
      margin-top: 1rem;
    }
  }

}

///**Custom radio input:
//
//<div class="c-radio">
//  <label>
//    <input type='radio'>
//    <span class='radio'></span>
//  </label>
//</div>
//**/
//
//
//
//.c-radio {
//  label{
//    display: flex;
//    margin:0;
//    position: relative;
//  }
//  input{
//    opacity:0;
//    position: absolute;
//    top:0;
//    left:0;
//    &:checked{
//      &+.radio{
//        opacity:1;
//        &:after{
//          opacity:1;
//        }
//      }
//    }
//  }
//  .radio{
//    display: block;
//    width:16px;
//    height:16px;
//    border: 1px solid #000;
//    border-radius: 50%;
//    position: relative;
//    margin-right: 10px;
//    opacity:.5;
//    &:after{
//      opacity:0;
//      content: '';
//      width:8px;
//      height:8px;
//      position: absolute;
//      top:50%;
//      left: 50%;
//      transform: translate(-50%,-50%);
//      background: #000;
//      border-radius: 50%;
//      transition: all .2s ease-in-out;
//    }
//  }
//}